// Internal dependencies
import {
	Customers,
	Dashboard,
	Login,
	Payment,
	Payouts,
	Plan,
	Plans,
	SignUp,
	PayLinks,
	DirectDebit,
	Customer,
	Payments,
	Settings,
	Landing,
	ResetPassword,
	SetPassword,
} from "pages";

export default [
	{
		path: "/",
		name: "Home",
		Component: Landing,
		isProtected: false,
	},
	{
		path: "/auth/sign-in",
		name: "Sign in",
		Component: Login,
		isProtected: false,
	},
	{
		path: "/auth/sign-up",
		name: "Sign up",
		Component: SignUp,
		isProtected: false,
	},
	{
		path: "/auth/reset-password",
		name: "Reset Password",
		Component: ResetPassword,
		isProtected: false,
	},
	{
		path: "/auth/set-password",
		name: "Set Password",
		Component: SetPassword,
		isProtected: false,
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		Component: Dashboard,
		isProtected: true,
	},
	{
		path: "/payments",
		name: "Payments",
		Component: Payments,
		isProtected: true,
	},
	{
		path: "/payments/:paymentId",
		name: "Payments",
		Component: Payment,
		isProtected: true,
	},
	{
		path: "/payouts",
		name: "Payouts",
		Component: Payouts,
		isProtected: true,
	},
	{
		path: "/customers",
		name: "Customers",
		Component: Customers,
		isProtected: true,
	},
	{
		path: "/customers/:customerId",
		name: "Customer",
		Component: Customer,
		isProtected: true,
	},
	{
		path: "/plans",
		name: "Plans",
		Component: Plans,
		isProtected: true,
	},
	{
		path: "/plans/:planId",
		name: "Plans",
		Component: Plan,
		isProtected: true,
	},
	{
		path: "/paylinks",
		name: "PayLinks",
		Component: PayLinks,
		isProtected: true,
	},
	{
		path: "/direct-debit/:key",
		name: "Direct Debit",
		Component: DirectDebit,
		isProtected: false,
	},
	{
		path: "/settings",
		name: "Settings",
		Component: Settings,
		isProtected: true,
	},
];
