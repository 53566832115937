/* eslint-disable react/prop-types */
import React from "react";

const DeskCirclePath = ({ className }) => (
	<svg
		className={className}
		width={406}
		height={406}
		viewBox="0 0 406 406"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle opacity="0.6" cx={203} cy={203} r={203} fill="#3AB75D" />
	</svg>
);

const CirclePath = () => (
	<svg
		width={640}
		height={640}
		viewBox="0 0 640 640"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle opacity="0.1" cx={320} cy={320} r={320} fill="#3AB75D" />
	</svg>
);

CirclePath.Desktop = DeskCirclePath;

export default CirclePath;
