/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import styled from "styled-components/macro";

// Internal dependencies
import Layout from "components/Layout";
import PageTitle from "components/PageTitle";
import CustomToggle from "components/CustomToggle";
import Card from "components/DashboardCard";
import analyticService from "../services/analytic.service";


const getKeys = (key) => {
	switch(key){
		case 'today': 
			return 'today';
		case 'last-7': 
			return 'last-7';
		case 'last-30': 
			return 'last-30';
		case '1year': 
			return '1year';
		default : 
			return 'today';
	}
}

const StyledCB = styled(CustomToggle)`
	background-color: var(--c-green);
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.5px;
	color: var(--white);

	&:hover {
		background-color: var(--c-darken-green);
		color: var(--white);
	}
`;

const Dashboard = () => {
	const [data, setData] = useState({ pending: 0, collected: 0, paidOut: 0 });
	const [filter, setFilter] = useState('today');

	const handleSelect = (eventKey) =>{
		// console.log(`selected---${eventKey}`);
		const mom = getKeys(eventKey);
		// console.log(mom);
		setFilter(mom);
	};

	const fetchData = async () => {
		const respo = await analyticService.fetchDash(filter);
		setData(respo.data);
	};

	useEffect(() => {
		fetchData();
	}, [filter]);

	return (
		<Layout>
			<div className="d-flex mb-5 justify-content-between">
				<div className="d-flex">
					<PageTitle text="Dashboard" className="mr-4" />

					<Dropdown>
						<Dropdown.Toggle as={StyledCB}>Add new</Dropdown.Toggle>

						<Dropdown.Menu className="border-0">
							<Dropdown.Item
								css="font-size:14px"
								href="/paylinks?f=12029844858"
							>
								Paylinks
							</Dropdown.Item>
							<Dropdown.Item
								css="font-size:14px"
								href="/customers?f=12090444858"
							>
								Customers
							</Dropdown.Item>
							<Dropdown.Item
								css="font-size:14px"
								href="/plans?f=12123672837"
							>
								Plans
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>

				<div>
					<Tab variant="pills" defaultActiveKey="today" onSelect={handleSelect}>
						<Nav.Item>
							<Nav.Link eventKey="today">Today</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="last-7">Last 7 days</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="last-30">Last 30 days</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="1year">1 year</Nav.Link>
						</Nav.Item>
					</Tab>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-lg-4">
					<div className="mb-3 mb-lg-0">
						<Card
							color="#FEA510"
							status="Pending"
							amount={data.pending || 0}
							tooltipText="The funds are currently being collected from your customers.The amount shown does not include Collect transaction Fees"
						/>
					</div>
				</div>
				<div className="col-12 col-lg-4">
					<div className="mb-3 mb-lg-0">
						<Card
							color="#4268EE"
							status="Collected"
							amount={data.collected || 0}
							tooltipText="These funds have being successfully collected from your customers.The amount shown does not include Collect transaction Fees"
						/>
					</div>
				</div>
				<div className="col-12 col-lg-4">
					<div className="mb-3 mb-lg-0">
						<Card
							color="#3AB75D"
							status="Paid out"
							amount={data.paidOut || 0}
							tooltipText="These funds have being successfully paid out to you."
						/>
					</div>
				</div>
			</div>
			{/* <div className="row">
				<div className="col-12 mt-5"><h3>Notifications</h3></div>
				{data.notifications && data.notifications.length > 0 && data.notifications.map((n) =>
					<Alert key={n.idx} variant={n.variant} style={{width: '100%'}}>
						{n.message} {n.link && <Alert.Link href={n.link} target="__blank">{n.linkTitle}</Alert.Link>}
					</Alert>
				)}
			</div> */}
		</Layout>
	);
};

const Tab = styled(Nav)`
	background: var(--white);
	border-radius: 3px;
	align-items: center;

	.nav-item .nav-link {
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: #000000;

		&.active {
			color: #1890ff;
			background: #f8f8f8;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 3px;
			padding: 15px 20px;
		}
	}
`;

export default Dashboard;
