import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Form, Row, Spinner  } from "react-bootstrap";
import styled, { css } from "styled-components/macro";
import { useFormik } from 'formik';
import Okra from "npm-okrajs";

// Internal dependencies
import Button from "components/Button";
import Input from "components/Input";
import OkraLogo from "assets/images/okra.png";
import "./auth/auth.scss";
import axiosClient from "axiosClient";
import endpoints from "utils/endpoints";
import { currencyForm } from '../utils/index';

const pStyle = css`
	font-size: 14px;
	line-height: 17px;
	color: var(--c-black);
`;

const DirectDebit = () => {
	const [pageLoading, setPageLoading] = useState(true);
	const [data, setData] = useState(null);
	const [serverError, setServerError] = useState(null);
	const [successMsg, setSuccessMsg] = useState(null);
	const [successTitle, setSuccessTitle] = useState(null);
	const { key } = useParams();

	const postFormData = (v, setSubmitting) => {
		// console.log(v);
		const val = v;
		val.accountNo = v.accountNumber;
		delete val.accountNumber;
		axiosClient.post(endpoints.directPaymentInitiate(key), val).then((r)=>{
			setSubmitting(false);
			// Okra.buildWithShortUrl({
			Okra.buildWithOptions({
				...r.data.widgetData,
				onSuccess(datam){
					axiosClient.post(endpoints.directPaymentResponse(key), {type: 'success', data: datam,txReference: r.data.payLoadData.txReference}).then(() => {
						setSuccessMsg('You have Successfully made a succesful transaction')
						setSuccessTitle('Transaction Successful!!!')
						setServerError(null);
					})
				},
				async onErroronError(json, error){ 
					await axiosClient.post(endpoints.directPaymentResponse(key), {type: "error", data: {error, json }, txReference: r.data.payLoadData.txReference });
					setSuccessMsg(null)
					setSuccessTitle(null)
					setServerError('An Error Occurred Please Try again');
				}
			})
		}).catch((err)=>{
			// console.log(err.response.data.message);
			setPageLoading(false);
			setServerError(err.response.data.message);
		})
	}

	const getData = () => {
		axiosClient.post(endpoints.directPaymentUrl(key)).then((r)=>{
			// console.log(r);
			setPageLoading(false);
			setData(r.data.payLoadData);
		}).catch((err)=>{
			// console.log(err.response.data.message);
			setPageLoading(false);
			setServerError(err.response.data.message);
		})
	}

	useEffect(()=>{
		getData();
	}, []);

	const { 
		handleBlur, handleChange, handleSubmit, values, isSubmitting, setSubmitting
	} = useFormik({
	initialValues: {
		firstName: '',
		lastName: '',
		email:'',
		bankName: '058',
		accountNumber: '',
	},
	onSubmit: (valueData) => 
		 postFormData(valueData, setSubmitting)
		// alert(JSON.stringify(valueData, null, 2));
	,
	});

	if(pageLoading){
		return (
			<div className="d-flex justify-content-center align-items-center vh-100 vw-100">
				<Spinner
					css="height: 100px;width: 100px"
					animation="grow"
					variant="primary"
				/>
			</div>
		)
	}
	if(serverError){
		return(
			<div css="background-color:#F8F8F8" className="auth-wrapper">
				<div className="auth-content">
					<StyledForm className="card d-flex flex-column align-items-center">
						<PageTitle>400 - Error</PageTitle>
						<p className="text-center text-danger">* {serverError}</p>
					</StyledForm>
				</div>
			</div>
		)
	}
	if(successMsg){
		return(
			<div css="background-color:#F8F8F8" className="auth-wrapper">
				<div className="auth-content">
					<StyledForm className="card d-flex flex-column align-items-center">
						<PageTitle>{successTitle || 'Success'}</PageTitle>
						<p className="text-center text-success">{successMsg}</p>
					</StyledForm>
				</div>
			</div>
		)
	}

	return(
		<div css="background-color:#F8F8F8" className="auth-wrapper">
			<div className="auth-content">
				<StyledForm className="card d-flex flex-column align-items-center" onSubmit={handleSubmit}>
					<div className="text-center mb-4">
						<PageTitle>{data?.customer}</PageTitle>
						<PageSubTitle className="mb-0">
							Set up a direct debit with {data?.customer}
						</PageSubTitle>
					</div>

					<TopInfoCard className="text-center mb-5">
						<p className="mb-1"> { currencyForm(data?.amount)}</p>
						<p className="mb-0"> { currencyForm(data?.amount)} ({data?.type} payment)</p>
					</TopInfoCard>

					<div className="w-100">
						<Row>
							<Col xs={12} md={6}>
								<Form.Group>
									<StyledLabel>First name</StyledLabel>
									<Input type="text2" name="firstName" onChange={handleChange} value={values.firstName} onBlur={handleBlur} />
								</Form.Group>
							</Col>
							<Col xs={12} md={6}>
								<Form.Group>
									<StyledLabel>Last name</StyledLabel>
									<Input type="text2" placeholder="" name="lastName" onChange={handleChange} value={values.lastName} onBlur={handleBlur} />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={12} md={6}>
								<Form.Group>
									<StyledLabel>Bank name</StyledLabel>
									<Input type="select2" placeholder="" name="bankName" onChange={handleChange} value={values.bankName} onBlur={handleBlur}>
										<option value='058' selected>Zenith Bank</option>
									</Input>
								</Form.Group>
							</Col>
							<Col xs={12} md={6}>
								<Form.Group>
									<StyledLabel>Account number</StyledLabel>
									<Input type="text2" placeholder="" name="accountNumber" onChange={handleChange} value={values.accountNumber} onBlur={handleBlur} />
								</Form.Group>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col xs={12} md={6}>
								<Form.Group>
									<StyledLabel>Email address</StyledLabel>
									<Input type="text2" placeholder="" name="email" onChange={handleChange} value={values.email} onBlur={handleBlur} />
								</Form.Group>
							</Col>
							<Col xs={12} md={6}>
								<Form.Group>
									<StyledLabel>Phone number</StyledLabel>
									<Input type="text2" placeholder="" name="phone" onChange={handleChange} value={values.phone} onBlur={handleBlur}  />
								</Form.Group>
							</Col>
						</Row>

						<BottomInfoCard className="mb-5">
							<p className="mb-0">
								This is a restricted Direct Debit, when {data?.customer} want to
								Charge you, we will ask for your permission first.
							</p>
						</BottomInfoCard>

						<div className="text-center">
							<Button
								iconBefore={() => (!isSubmitting ? <PadLockIcon /> : <Spinner animation="grow" css="width:20px;height:20px;" variant="white" />)}
								onClick={handleSubmit}
								className="mb-4"
								variant="green"
								text="Set up Direct Debit"
								disabled={isSubmitting}
							/>

							<div>
								<img
									className="mr-2"
									width="25"
									height="25"
									src={OkraLogo}
									alt="Okra logo"
								/>

								<span css={pStyle}>Powered by Okra.ng</span>
							</div>
						</div>
					</div>
				</StyledForm>
			</div>
		</div>
	)
};

const PadLockIcon = () => (
	<svg
		width={14}
		height={18}
		viewBox="0 0 14 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.00004 0.666992C9.24067 0.666992 11.0682 2.43558 11.1629 4.65292L11.1667 4.83366V7.33366C12.5474 7.33366 13.6667 8.45295 13.6667 9.83366V14.8337C13.6667 16.2144 12.5474 17.3337 11.1667 17.3337H2.83337C1.45266 17.3337 0.333374 16.2144 0.333374 14.8337V9.83366C0.333374 8.45295 1.45266 7.33366 2.83337 7.33366V4.83366C2.83337 2.53247 4.69885 0.666992 7.00004 0.666992ZM11.1667 9.00033H2.83337C2.37314 9.00033 2.00004 9.37342 2.00004 9.83366V14.8337C2.00004 15.2939 2.37314 15.667 2.83337 15.667H11.1667C11.6269 15.667 12 15.2939 12 14.8337V9.83366C12 9.37342 11.6269 9.00033 11.1667 9.00033ZM7.14693 2.3379L7.00004 2.33366C5.66864 2.33366 4.58032 3.37443 4.50428 4.68676L4.50004 4.83366V7.33366H9.50004V4.83366C9.50004 3.50226 8.45927 2.41394 7.14693 2.3379L7.00004 2.33366L7.14693 2.3379Z"
			fill="white"
		/>
	</svg>
);

const StyledForm = styled.form`
	background: var(--white);
	border-radius: 6px;
	padding: 3rem 1rem;
	border: none;
	margin-bottom: 1.6rem;

	@media screen and (min-width: 992px) {
		padding: 4rem 4rem;
	}
`;

const StyledLabel = styled(Form.Label)`
	font-size: 14px;
	line-height: 17px;
	color: var(--c-black);
`;

const PageTitle = styled.h3`
	font-size: 20px;
	line-height: 24px;
	letter-spacing: -0.5px;
	color: var(--c-black);
`;

const PageSubTitle = styled.p`
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.5px;
	color: var(--c-black);
`;


const TopInfoCard = styled.div`
	padding: 18px 0 28px;
	background-color: #dde0e3;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 5px;
	width: 90%;

	p {
		${pStyle}

		:first-child {
			font-weight: 500;
		}

		:last-child {
			font-weight: 300;
		}
	}
`;

const BottomInfoCard = styled.div`
	padding: 12px 10px;
	background-color: rgba(254, 202, 45, 0.3);
	border-radius: 3px;
	width: 100%;

	p {
		font-size: 14px;
		line-height: 17px;
		font-weight: 300;
		color: var(--c-black);
	}
`;

export default DirectDebit;
