/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";

const CustomToggle = React.forwardRef(
	({ children, onClick, className, ...rest }, ref) => (
		<button
			{...rest}
			type="button"
			ref={ref}
			className={`btn d-flex align-items-center ${className.replace(
				"dropdown-toggle",
				""
			)}`}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
			<svg
				className="ml-1"
				width={24}
				height={24}
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 15L7.75696 10.757L9.17196 9.34302L12 12.172L14.828 9.34302L16.243 10.757L12 15Z"
					fill="white"
				/>
			</svg>
		</button>
	)
);

export default CustomToggle;
