// External dependencies
import { combineReducers } from "redux";

// Internal dependencies
import authReducer from "./reducers/authReducer";

const rootReducer = combineReducers({
	auth: authReducer
});

export default rootReducer;
