// External dependencies
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

// Internal dependencies
import rootReducer from "./rootReducer";

const persistConfig = {
	key: "cd-root",
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
	const store = createStore(persistedReducer, applyMiddleware(thunk));
	const persistor = persistStore(store);
	return { store, persistor };
};
