import axiosClient from "axiosClient";
import endpoints from "utils/endpoints";

const service = {
    fetch:async(page=1, limit=10) =>{
        const respo =  await axiosClient.get(`${endpoints.planFetchAll}?page=${page}&&limit=${limit}`);
        return respo;
    },

    fetchOne:async(id) =>{
        const respo =  await axiosClient.get(endpoints.planFetchOne(id));
        return respo;
    },
    create:async(data) => {
        // console.log(data);
        const respo = await axiosClient.post(`${endpoints.planFetchAll}/create`, data);
        return respo;
    }
}
export default service;