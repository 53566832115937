import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

// Internal dependencies
import Input from "components/Input";
import Button from "components/Button";
import { signInAction } from "redux/actions/authActions";
import authService from "../../services/auth.service";
import "./auth.scss";

const SetPassword = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [error, setError] = useState(null);
	// console.log(state);

	const postFormData = async (v, setSubmitting) => {
		setError(null);
		try {
			const response = await authService.login(v.email, v.password);
			dispatch(signInAction(response.data));
			setSubmitting(false);
			history.push("/dashboard");
		} catch (err) {
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		isSubmitting,
		setSubmitting,
	} = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
		// alert(JSON.stringify(valueData, null, 2));
	});

	return (
		<div className="row">
			<div className="col-12 col-lg-7 px-0">
				<div className="auth-wrapper">
					<div className="auth-content">
						<form className="card auth-card" onSubmit={handleSubmit}>
							<h3 className="auth-card-title text-left">Set a Password</h3>
							<p className="mb-5 auth-card-subtext">
								Protect your account, set a secure password
							</p>

							{error && (
								<div className="text-danger text-center">
									<p>*{error}</p>
								</div>
							)}

							<div>
								<Form.Group>
									<Input
										type="text"
										placeholder="Password"
										name="password"
										onChange={handleChange}
										value={values.email}
										onBlur={handleBlur}
									/>
								</Form.Group>
								<Form.Group className="mb-5">
									<Input
										type="text"
										placeholder="Confirm password"
										name="confirm-password"
										onChange={handleChange}
										value={values.email}
										onBlur={handleBlur}
									/>
								</Form.Group>

								<WarningCard className='d-lg-none mb-5' isValid={false}>
									<p className="title">
										Please ensure your password has at least 8 characters
										including the following:
									</p>

									<ul className="list-unstyled">
										<li>- At least 1 uppercase characters</li>
										<li>- At least 1 digit</li>
										<li>- At least 1 symbol characters</li>
									</ul>
								</WarningCard>

								<div className="d-flex flex-column">
									<Button
										iconBefore={() =>
											!isSubmitting ? (
												""
											) : (
												<Spinner
													animation="grow"
													css="width:20px;height:20px;"
													variant="white"
												/>
											)
										}
										onClick={handleSubmit}
										className="w-100"
										variant="green"
										text={!isSubmitting ? "SAVE AND CONTINUE" : "Loading..."}
										disabled={isSubmitting}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className="d-none d-lg-block col-12 col-lg-5 px-0">
				<div className="auth-wrapper">
					<div className="auth-content px-0">
						<WarningCard isValid={false}>
							<p className="title">
								Please ensure your password has at least 8 characters including
								the following:
							</p>

							<ul className="list-unstyled">
								<li>- At least 1 uppercase characters</li>
								<li>- At least 1 digit</li>
								<li>- At least 1 symbol characters</li>
							</ul>
						</WarningCard>
					</div>
				</div>
				ki
			</div>
		</div>
	);
};

const WarningCard = styled.div`
	border-radius: 6px;
	padding: 42px 26px;
	color: ${({ isValid }) => (isValid ? "var(--c-black)" : "#F90217")};
	background: ${({ isValid }) =>
		isValid ? "rgba(221, 224, 227, 0.2)" : "rgba(249,2,23, 0.05)"};
	font-size: 16px;
	line-height: 25px;

	@media screen and (min-width: 400px) {
		width: 398px;
	}

	.title {
		letter-spacing: -0.2px;
		font-weight: 500;
	}

	ul.list-unstyled li {
		letter-spacing: -0.5px;
		font-weight: 300;
	}
`;

export default SetPassword;
