/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const PageTitle = ({ text, ...rest }) => <H3 {...rest}>{text}</H3>;
const PageSubTitle = ({ text, ...rest }) => <P {...rest}>{text}</P>;
const PTitle = ({ text, ...rest }) => <PH4 {...rest}>{text}</PH4>;

const H3 = styled.h3`
	font-weight: 500;
	font-size: 30px;
	line-height: 36px;
	letter-spacing: -1px;
	color: var(--c-black);
`;

const P = styled.p`
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: var(--c-black);
`;

const PH4 = styled.h4`
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 1.3125rem;
	letter-spacing: -0.5px;
	color: var(--c-black);
`;

PageTitle.propTypes = {
	text: PropTypes.string.isRequired,
};

PageSubTitle.propTypes = {
	text: PropTypes.string.isRequired,
};

PTitle.propTypes = {
	text: PropTypes.string.isRequired,
};

PageTitle.Sub = PageSubTitle;
PageTitle.H4 = PTitle;

export default PageTitle;
