const endpoints = {};
// auth url
endpoints.login = `auth/login`;
endpoints.register = `auth/create-account`;

// dashboard
endpoints.dashboard = `analytics`;

// customer url
endpoints.customerFetchAll = `customers`;
endpoints.customerFetchOne = (id) => `customers/${id}`;

// Paylink url
endpoints.paylinkFetchAll = `paylinks`;
endpoints.paylinkFetchOne = (id) => `paylinks/${id}`;
endpoints.directPaymentCreate = `direct/payment/initiate`;

// Payment url
endpoints.paymentFetchAll = `payments`;
endpoints.paymentFetchOne = (id) => `payments/${id}`;

// plan url
endpoints.planFetchAll = `plans`;
endpoints.planFetchOne = (id) => `plans/${id}`;

// payout url
endpoints.payoutFetchAll = `payouts`;
endpoints.payoutFetchOne = (id) => `payouts/${id}`;

// upload url
endpoints.upload = `upload`

// direct debit
endpoints.directPaymentUrl= (id) => `direct-debit/${id}`;
endpoints.directPaymentInitiate = (id) => `direct-debit/initiate/${id}`;
endpoints.directPaymentResponse = (id) => `direct-debit/response/${id}`;

// settings
endpoints.setting = `settings`;
endpoints.bankUrl = `${endpoints.setting}/bank`;

export default endpoints;