/* eslint-disable react/prop-types */

import React from "react";
import styled from "styled-components";

const Card = ({ color, status, amount, tooltipText }) => (
	<Div color={color} className="position-relative">
		<div className="tip">
			<svg
				width={20}
				height={20}
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM10 8.75C9.35896 8.75 8.83062 9.23255 8.75841 9.85422L8.75 10V15C8.75 15.6904 9.30964 16.25 10 16.25C10.641 16.25 11.1694 15.7674 11.2416 15.1458L11.25 15V10C11.25 9.30964 10.6904 8.75 10 8.75ZM10 3.75C9.30964 3.75 8.75 4.30964 8.75 5C8.75 5.69036 9.30964 6.25 10 6.25C10.6904 6.25 11.25 5.69036 11.25 5C11.25 4.30964 10.6904 3.75 10 3.75Z"
					fill="#262626"
				/>
			</svg>
		</div>

		<div className="tip-content text-center">{tooltipText}</div>
		<p className="status">{status}</p>
		<p className="price">{amount}</p>
	</Div>
);

const Div = styled.div`
	height: 228px;
	background: #ffffff;
	border-radius: 10px;
	padding: 24px 25px;

	::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 7px;
		border-radius: 10px;
		background-color: ${({ color }) => color};
		height: 100%;
	}

	.tip-content {
		position: absolute;
		z-index: 3;
		top: 0;
		background: #000000;
		opacity: 0.9;
		border-radius: 10px;
		font-size: 14px;
		line-height: 18px;
		color: var(--white);
		padding: 16px 10px;
		left: 0;
		right: 0;
		visibility: hidden;
		pointer-events: none;
	}

	.tip {
		position: absolute;
		top: 14px;
		right: 20px;
		cursor: pointer;

		&:hover ~ .tip-content {
			visibility: visible;
		}
	}

	.status {
		font-size: 20px;
		line-height: 24px;
		letter-spacing: -1.5px;
		color: #323232;

		@media screen and (min-width: 500px) and (max-width: 992px) {
			font-size: 24px;
			line-height: 28px;
		}
		@media screen and (min-width: 1200px) {
			font-size: 24px;
			line-height: 28px;
		}
	}

	.price {
		font-size: 20px;
		line-height: 28px;
		color: #262626;
		font-family: "Mulish";
		margin: 55px 10px 64px;

		@media screen and (min-width: 500px) and (max-width: 992px) {
			font-size: 30px;
			line-height: 38px;
		}
		@media screen and (min-width: 1200px) {
			font-size: 30px;
			line-height: 38px;
		}
	}
`;


export default Card;
