/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import routes from "routes";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated } = useSelector((state) => state.auth);

	const renderRoute = (props, RComponent) => {
		const crumbList = routes
			// Get all routes that contain the current one.
			.filter(({ path }) => props.match.path.includes(path))
			// Swap out any dynamic routes with their param values.
			// E.g. "/pizza/:pizzaId" will become "/pizza/1"
			.map(({ path, ...rest }) => ({
				path: Object.keys(props.match.params).length
					? Object.keys(props.match.params).reduce(
							(pathe, param) =>
								pathe.replace(`:${param}`, props.match.params[param]),
							path
					  )
					: path,
				...rest,
			}));

		const crumbs = {
			crumbList,
		};

		return (
			<div className="p-8">
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<RComponent {...props} {...crumbs} />
			</div>
		);
	};

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					renderRoute(props, Component)
				) : (
					<Redirect
						to={{ pathname: "/auth/sign-in", state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
