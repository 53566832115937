import React from 'react';

const PayoutIcon = () => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M23.5 12.48C23.5 18.5471 18.3707 23.5 12 23.5C5.62929 23.5 0.5 18.5471 0.5 12.48C0.5 6.41282 5.62929 1.45996 12 1.45996C18.3707 1.45996 23.5 6.41282 23.5 12.48Z"
			stroke="white"
		/>
		<path
			d="M15.84 11.92V13.8H17.74V14.98H15.84V20H14.46L9.62 9.3V20H8.16V14.98H6.28V13.8H8.16V11.92H6.28V10.74H8.16V5.76H9.54L14.38 16.6V5.76H15.84V10.74H17.74V11.92H15.84Z"
			fill="#F4F4F4"
		/>
	</svg>
);

export default PayoutIcon;
