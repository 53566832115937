/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

// Internal dependencies
import Layout from "components/Layout";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { testPaymentData } from "utils/data";
import { handleTableChange } from "utils";
import styled from "styled-components";
import Button from "components/Button";
import PageTitle from "components/PageTitle";
import paymentService from "services/payment.service";
import Moment from "react-moment";
import ModalContainer from "components/ModalContainer";


const Payments = () => {
	const [state, setState] = useState({
		page: 1,
		data: [],
	});
	const [deleteId, setDeleteId] =useState(null);
	const [showDelModal, setShowDelModal] = useState(false);

	const handleDelModalHide = () => { 
		setDeleteId(null);
		setShowDelModal(false)
	};
	const handleDelModalShow = () => setShowDelModal(true);

	const getData = async () => {
		const all = await paymentService.fetch();
		setState({
			page: all.data.page,
			data: all.data.payments,
		});
	};
	const cancelPayment = () => {
		// console.log('deleting...===>', deleteId);	
		// if(deleteId==null) console.log('Not payment selected');
		// return
	}

	const columns = useMemo(
		() =>[
		{
			dataField: "customer",
			text: "Customer",
			formatter: (cell, { _id, customer }) => (
				<StyledLink to={`/payments/${_id}`}>{ customer ? `${customer.firstName} ${customer.lastName}` : '-'}</StyledLink>
			),
		},
		{
			dataField: "charged",
			text: "Amount",
		},
		{
			dataField: "txReference",
			text: "Reference",
		},
		{
			dataField: "type",
			text: "Type",
			formatter: (cell, { type }) =>(
				 <span className="text-center">{type==='one-time'? 'One Time' : 'Recurring'}</span>
				 )
		},
		{
			dataField: "paidAt",
			text: "Paid On",
			formatter: (cell, { paidAt }) => <Moment date={paidAt} />,
		},
		{
			dataField: "_id",
			text: "",
			align: "right",
			headerClasses: "",
			formatter: (cell, { _id, type }) => (
				<div className="d-flex">
					{type === 'recurring' && <Button.TableDelBtn
						text=""
						onClick={()=>{
							setDeleteId(_id);
							handleDelModalShow()
						}}
					/>}
				</div>
			),
		},
	]);

	useEffect(() => {
		getData();
	}, []);

	return (
		<Layout>
			<CancelPayment handleDelModalHide={handleDelModalHide} showDelModal={showDelModal} confirmAction={cancelPayment} />
			<div className="d-flex align-items-center justify-content-between mb-4">
				<PageTitle text="Payments" />
				<p style={{ display: 'none' }}>{deleteId}</p>
				<div className="d-flex">
					<Button variant="outline" text="Export" className="mr-2 px-5" />
					<Button variant="green" className="px-4" text="New Payment" />
				</div>
			</div>

			<div>
				<Pagination size={testPaymentData.length}>
					<Table
						keyField="email"
						data={state.data}
						page={state.page}
						columns={columns}
						onTableChange={(type, { page, sizePerPage }) =>
							handleTableChange(
								type,
								{ page, sizePerPage },
								testPaymentData,
								setState
							)
						}
						noDataIndication={() => (
							<p className="text-center mb-0">
								You haven&apos;t gotten any payment yet.
							</p>
						)}
					/>
				</Pagination>
			</div>
		</Layout>
	);
};

const CancelPayment = (props) => {
	const { showDelModal, handleDelModalHide, confirmAction } = props;
return (<ModalContainer
	modalTitle="Cancel plan"
	show={showDelModal}
	onHide={handleDelModalHide}
>
	<p>
		Cancelling this plan will stop all active subscriptions and prevent
		anyone from being added or joining.
	</p>
	<p>Please confirm that you want to cancel this plan.</p>
	<p className="mb-5">
		Any payments that have already been created, but are still pending,
		will need to be cancelled manually from the payments page.
	</p>

	<div className="d-flex">
		<Button
			variant="outline"
			className="px-4 mr-4"
			text="Back"
			onClick={confirmAction}
		/>
		<Button variant="red" className="px-4" text="Yes, cancel plan" onClick={handleDelModalHide} />
	</div>
</ModalContainer>)
}

const StyledLink = styled(Link)`
	color: #3ab75d;

	:hover {
		color: #3ab75d;
	}
`;

export default Payments;
