/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Col, Form, Spinner } from "react-bootstrap";
import Moment from "react-moment";
import { useFormik } from "formik";

// Internal dependencies
import Layout from "components/Layout";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
// import { testPayLinksData } from "utils/data";
import { copyToClipboard, handleTableChange, useQuery } from "utils";
import PageTitle from "components/PageTitle";
import Button from "components/Button";
import ModalContainer from "components/ModalContainer";
import Input from "components/Input";
import ModalFormLabel from "components/ModalFormLabel";
import paylinkService from "services/paylinks.service";
import config from "env";
import { paySchema } from "schema/schema";

const PayLinks = () => {
	const fromDashboard = useQuery().get("f");
	const [state, setState] = useState({
		page: 1,
		data: [],
	});
	const [showModal, setShowModal] = useState(false);
	const [showDelModal, setShowDelModal] = useState(false);

	const handleModalShow = () => setShowModal(true);
	const handleModalHide = () => setShowModal(false);

	const handleDelModalShow = () => setShowDelModal(true);
	const handleDelModalHide = () => setShowDelModal(false);

	const [error, setError] = useState(null);

	const columns = useMemo(
		() => [
			{
				dataField: "payName",
				text: "Page name",
				formatter: (cell, { payName }) => (
					<StyledLink to="/#">{payName}</StyledLink>
				),
			},
			{
				dataField: "payments",
				text: "Payments",
				formatter: (cell, { payments }) => (
					<StyledLink to="/#">{payments || 0 }</StyledLink>
				),
			},
			{
				dataField: "createdAt",
				text: "Created on",
				formatter: (cell, { createdAt }) => <Moment date={createdAt} />,
			},
			{
				dataField: "amount",
				text: "Amount",
			},
			{
				dataField: "key",
				text: " ",
				headerClasses: "bs-table-col-action",
				formatter: (cell, { key }) => (
					<div className="d-flex align-items-center">
						<Button.TableCopyBtn
							text="Copy Link"
							onClick={() => {
								copyToClipboard(`${config.siteUrl}/${key}`);
								// eslint-disable-next-line no-alert
								alert("copied to ClipBoard");
							}}
						/>

						<Button.TableDelBtn
							text="Delete Link"
							onClick={handleDelModalShow}
						/>
					</div>
				),
			},
		],
		[]
	);

	const getData = async () => {
		const all = await paylinkService.fetch();
		// console.log(all);
		setState({
			page: all.data.page,
			data: all.data.paylinks,
		});
	};

	useEffect(() => {
		getData();
	}, []);

	// Show Modal if redirected from dashboard
	useEffect(() => {
		if (fromDashboard === "12029844858") {
			setShowModal(true);
		}
	}, []);

	const postFormData = async (v, setSubmitting) => {
		setError(null);
		try {
			// const val = v;
			await paylinkService.create(v);
			setSubmitting(false);
			getData();
			setShowModal(false);

			// history.push("/dashboard");
		} catch (err) {
			// console.log(err);
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		errors, touched,
		isSubmitting,
		setSubmitting,
		isValid,
	} = useFormik({
		initialValues: {
			payName: "",
			amount: "",
			type: "one-time",
		},
		validationSchema: paySchema,
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
	});

	return (
		<>
			<ModalContainer
				modalTitle="Delete Paylink"
				show={showDelModal}
				onHide={handleDelModalHide}
			>
				<p>
					Are you sure you want to delete this paylink? This link will no longer
					be available.
				</p>

				<div className="d-flex">
					<Button
						variant="outline"
						className="px-4 mr-4"
						text="Cancel"
						onClick={handleDelModalHide}
					/>
					<Button variant="red" className="px-4" text="Yes, delete Paylink" />
				</div>
			</ModalContainer>

			<Layout>
				<ModalContainer
					modalTitle="Create a new paylink"
					show={showModal}
					onHide={handleModalHide}
				>
					<Form onSubmit={handleSubmit}>
						{error && (
							<div className="text-danger text-center">
								<p>*{error}</p>
							</div>
						)}
						<Form.Group>
							<ModalFormLabel>Paylink Name</ModalFormLabel>
							<Input
								type="text2"
								name="payName"
								onChange={handleChange}
								value={values.payName}
								onBlur={handleBlur}
							/>
							{errors.payName && touched.payName && (
								<span className="error">{errors.payName}</span>
							)}
						</Form.Group>

						<Form.Row>
							<Col sm={6}>
								<Form.Group>
									<ModalFormLabel>Amount</ModalFormLabel>
									<Input
										type="text2"
										name="amount"
										onChange={handleChange}
										value={values.amount}
										onBlur={handleBlur}
									/>
									{errors.amount && touched.amount && (
										<span className="error">{errors.amount}</span>
									)}
								</Form.Group>
							</Col>
							<Col sm={6}>
								<Form.Group>
									<ModalFormLabel>Currency</ModalFormLabel>
									<Input
										type="text2"
										name="currency"
										value="NGN"
										readOnly
									/>
								</Form.Group>
							</Col>
						</Form.Row>

						{/* <Form.Group>
						<ModalFormLabel>Redirect Url (optional)</ModalFormLabel>
						<Input
							type="text2"
							name="redirectUrl"
							// onChange={handleChange}
							// value={url}
							// onBlur={handleBlur}
							readOnly
						/>
					</Form.Group> */}

						{/* <Form.Group className="mb-5">
							<ModalFormLabel>Paylink Generated Url</ModalFormLabel>
							<div className="d-flex">
								<Input
									type="text2"
									name="redirectUrl"
									// onChange={handleChange}
									// value={url}
									// onBlur={handleBlur}
									readOnly
									css="border-bottom-right-radius:0px;
									border-top-right-radius: 0;
									border-right: none;"
								/>
								<button
									type="button"
									className="btn"
									css="border:1px solid #C4C4C4;margin-left:-2px"
								>
									Copy
								</button>
							</div>
						</Form.Group> */}

						<Button
							variant="green"
							className="px-4"
							text={
								isSubmitting ? <Spinner animation="grow" /> : "Create Paylink"
							}
							onClick={handleSubmit}
							disabled={isSubmitting || !isValid}
						/>
					</Form>
				</ModalContainer>

				<div className="d-flex align-items-center justify-content-between mb-4">
					<div className="w-75">
						<PageTitle text="PayLinks" />
						<PageTitle.Sub text="Paylinks let you take the same one-off payment from lots of different people. They're great if you're collecting donations, or have packages at different prices. You can share them on your website or in emails." />
					</div>

					<Button
						variant="green"
						className="px-4"
						text="New Paylinks"
						onClick={handleModalShow}
					/>
				</div>

				<div>
					<Pagination size={state.data.length}>
						<Table
							keyField="customer"
							data={state.data}
							page={state.page}
							columns={columns}
							onTableChange={(type, { page, sizePerPage }) =>
								handleTableChange(
									type,
									{ page, sizePerPage },
									state.data,
									setState
								)
							}
							noDataIndication={() => (
								<p className="text-center">
									You haven&apos;t added any member yet.
								</p>
							)}
						/>
					</Pagination>
				</div>
			</Layout>
		</>
	);
};

const StyledLink = styled(Link)`
	color: #3ab75d;

	:hover {
		color: #3ab75d;
	}
`;

export default PayLinks;
