import axiosClient from "axiosClient";
import endpoints from "utils/endpoints";

const service = {
    fetch:async(page=1, limit=10) =>{
        const respo =  await axiosClient.get(`${endpoints.payoutFetchAll}?page=${page}&&limit=${limit}`);
        return respo;
    },

    fetchOne:async(id) =>{
        const respo =  await axiosClient.get(endpoints.payoutFetchOne(id));
        return respo;
    },
    create:async(data) => {
        const respo = await axiosClient.post(endpoints.payoutFetchAll, data);
        return respo;
    }
}
export default service;