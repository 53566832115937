// Internal dependencies
import { LOGINSUCCESS, SIGNOUT, USERFETCH } from "redux/actionTypes";
import {
	 storeToken,
	clearStorage,
} from "utils/auth";

const signInAction = (data) => {
	storeToken(data.token);
	return({
		type: LOGINSUCCESS,
		payload: data,
	});
}

const fetchUserAction = (data) => ({
	type: USERFETCH,
	payload: data
});

const signOutAction = () => {
	clearStorage();
	return {
		type: SIGNOUT,
	};
};

export { signInAction, signOutAction, fetchUserAction };
