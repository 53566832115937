// Internal dependencies
import { LOGINSUCCESS, LOGINFAIL, SIGNOUT, USERFETCH } from "redux/actionTypes";

const initialState = {
	isAuthenticated: false,
	token: null,
	user: null
};

const reducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case LOGINSUCCESS:
			return {
				isAuthenticated: true,
				token: payload.token,
				user: payload
			};

			case USERFETCH:
				return{
					...state,
					user:{ ...payload }
				}

		case LOGINFAIL:
			return {
				isAuthenticated: false,
				authError: payload.message || payload.data.message,
			};

		case SIGNOUT:
			return {
				isAuthenticated: false,
				token: null,
			};

		default:
			return state;
	}
};

export default reducer;
