/* eslint-disable react/require-default-props */
import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import styled from "styled-components";

const ModalContainer = ({
	show,
	onHide,
	children,
	modalTitle,
	dialogClassName = "modal-left",
}) => (
	<Modal
		show={show}
		onHide={onHide}
		backdrop="static"
		keyboard={false}
		centered
		contentClassName="cs-content"
		dialogClassName={dialogClassName}
	>
		<Header className="d-flex align-items-center justify-content-between">
			<h5 className='mb-0'>{modalTitle}</h5>

			<CloseButton className="btn" type="button" onClick={onHide}>
				<svg
					width={24}
					height={24}
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6 6L18.7742 18.7742"
						stroke="#14142B"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M6 18.7742L18.7742 5.99998"
						stroke="#14142B"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</CloseButton>
		</Header>
		<Body>{children}</Body>
	</Modal>
);

const CloseButton = styled.button`
	display: none;

	@media screen and (min-width: 768px) {
		display: block;
		/* position: absolute; */
		/* right: 30px;
		top: 18px;
		z-index: 4; */
	}
`;

const Body = styled(Modal.Body)`
	@media screen and (min-width: 768px) {
		padding: 40px 34px 48px ;
	}
`;

const Header = styled.header`
	border-bottom: 1px solid #c4c4c4;
	padding: 5px 20px 4px 34px;

	h5 {
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.3px;
		color: var(--c-black);
		font-weight: 400;
	}
`;


ModalContainer.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	dialogClassName: PropTypes.string,
	modalTitle: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};
export default ModalContainer;
