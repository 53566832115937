/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled, {css} from "styled-components/macro";
import PropTypes from "prop-types";

const ccBtnStyle = css`
	font-size: 14px;
	line-height: 17px;
`;

const handleBgColorType = (variant) => {
	switch (variant) {
		case "green":
			return {
				bgColor: "var(--c-green)",
				textColor: "var(--white)",
				border: "none",
				hoveredBgColor: "var(--c-darken-green)",
				hoveredTextColor: "var(--white)",
				boxShadow: "none",
				fontWeight: 500,
			};
		case "red":
			return {
				bgColor: "var(--c-red)",
				textColor: "var(--white)",
				border: "none",
				hoveredBgColor: "var(--c-darken-red)",
				hoveredTextColor: "var(--white)",
				boxShadow: "none",
				fontWeight: 500,
			};
		case "white":
			return {
				bgColor: "var(--c-white)",
				textColor: "var(--c-black)",
				border: "none",
				hoveredBgColor: "var(--white)",
				hoveredTextColor: "var(--c-black)",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
				fontWeight: "normal",
			};

		case "outline":
			return {
				bgColor: "transparent",
				textColor: "var(--c-black)",
				border: "1px solid var(--c-black)",
				hoveredBgColor: "var(--c-black)",
				hoveredTextColor: "var(--white)",
				boxShadow: "",
				fontWeight: "normal",
			};

		default:
			return "#fff";
	}
};

const CopyLinkBtn = ({ onClick, text }) => (
	<button css={ccBtnStyle} type="button" className="mr-3 btn" onClick={onClick}>
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 6V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H20C20.2652 2 20.5196 2.10536 20.7071 2.29289C20.8946 2.48043 21 2.73478 21 3V17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18H17V21C17 21.552 16.55 22 15.993 22H4.007C3.87513 22.0008 3.7444 21.9755 3.62232 21.9256C3.50025 21.8757 3.38923 21.8022 3.29566 21.7093C3.20208 21.6164 3.12779 21.5059 3.07705 21.3841C3.02632 21.2624 3.00013 21.1319 3 21L3.003 7C3.003 6.448 3.453 6 4.01 6H7ZM5.003 8L5 20H15V8H5.003ZM9 6H17V16H19V4H9V6Z"
				fill="#3AB75D"
			/>
		</svg>{" "}
		{text}
	</button>
);

const DelBtn = ({ onClick, text }) => (
	<button css={ccBtnStyle} type="button" className="btn" onClick={onClick}>
		<svg
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z"
				fill="#F90217"
			/>
		</svg>{" "}
		{text}
	</button>
);

// eslint-disable-next-line react/prop-types
const Button = ({ text, variant, type, iconBefore, className, ...rest }) => {
	const Icon = iconBefore;
	// eslint-disable-next-line react/button-has-type
	return (
		<StyledButton
			variant={variant}
			type={type || "button"}
			className={`btn ${className}`}
			{...rest}
		>
			{iconBefore() && (
				<span className="mr-2">
					<Icon />
				</span>
			)}

			{text}
		</StyledButton>
	);
};

Button.TableCopyBtn = CopyLinkBtn;
Button.TableDelBtn = DelBtn;

const StyledButton = styled.button`
	height: 50px;
	border-radius: 5px;
	background-color: ${({ variant }) => handleBgColorType(variant).bgColor};
	color: ${({ variant }) => handleBgColorType(variant).textColor};
	border: ${({ variant }) => handleBgColorType(variant).border};
	box-shadow: ${({ variant }) => handleBgColorType(variant).boxShadow};
	font-weight: ${({ variant }) => handleBgColorType(variant).fontWeight};

	font-size: 0.875rem;
	line-height: 1.3125rem;
	letter-spacing: -0.5px;

	&:hover {
		background-color: ${({ variant }) =>
			handleBgColorType(variant).hoveredBgColor};
		color: ${({ variant }) => handleBgColorType(variant).hoveredTextColor};
	}
`;

Button.defaultProps = {
	variant: "blue",
	iconBefore: () => null,
};

CopyLinkBtn.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

DelBtn.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

Button.propTypes = {
	type: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	variant: PropTypes.string,
	iconBefore: PropTypes.func,
};

export default Button;
