/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

export const BankAccountSchema = yup.object().shape({
    length:yup.number().default(0),
    bankCode: yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Firstname is required"),
    accountNumber: yup.string().min(10, "invalid Account Number").max(10, 'invalid Account Number').required(),
    accountName: yup.string().required(),
    bvn: yup.string().when("length",{
        is: yup.ref('length') > 0,
        then: yup.number().required().max(10).min(10),
    }),
    primary: yup.boolean()
});

export const profileSchema =yup.object().shape({
    companyName: yup.string().required(),
    phone: yup.string().required(),
    website: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string(),
    address3: yup.string(),
})
export const paySchema = yup.object().shape({
    payName: yup.string().required('paylink name is required'),
    amount: yup.number().required(),
    type: yup.string(),
})

export const payOutSchema = yup.object().shape({
    amount: yup.number().required(),
    account: yup.string().required(),
})

export const loginSchema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required()
})
export const registerSchema = yup.object().shape({
    name: yup.string().required(),
    company: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().required(),
    password: yup.string().required(),
})