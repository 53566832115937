import config from "../env";

const USER_TOKEN = config.authToken;
// const ROLE = "role";

const storeToken = (token) => {
	// localStorage.setItem(ROLE, role);
	localStorage.setItem(USER_TOKEN, token);
};

const getUserToken = () => localStorage.getItem(USER_TOKEN);

// const getUserRole = () => localStorage.getItem(ROLE);

const clearStorage = () => {
	localStorage.clear();
	window.location.reload();
};

export { storeToken, getUserToken,  clearStorage };
