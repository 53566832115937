/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "./table.scss";

const Table = ({
	keyField,
	selectRow,
	expandRow,
	data,
	columns,
	rowEvents,
	noDataIndication,
	...rest
}) => (
	<BootstrapTable
		bootstrap4
		keyField={keyField}
		bordered={false}
		classes="bs-table table-responsive-sm"
		rowClasses="bs-table-body"
		condensed
		data={data}
		columns={columns}
		selectRow={selectRow}
		expandRow={expandRow}
		rowEvents={rowEvents}
		noDataIndication={noDataIndication}
		{...rest}
	/>
);

export default Table;
