/* eslint-disable react/jsx-props-no-spreading */
// External dependencies
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Form } from "react-bootstrap";

// Internal dependencies
import arrowDown from 'assets/svgs/arrow-down.svg'

const Input = ({ type, variant, className, children, ...rest }) => {
	if (type === "select") {
		return (
			<StyledInput
				as="select"
				className="form-control border-0"
				{...rest}
			>
			{children}
			</StyledInput>
		);
	}

	if (type === "select2") {
		return (
			<StyledInput2
				as="select"
				className="form-control"
				{...rest}
			>
			{children}
			</StyledInput2>
		);
	}

	if (type === "textarea") {
		return (
			<StyledInput
				as="textarea"
				className="form-control border-0"
				rows={4}
				{...rest}
			/>
		);
	}

	if (type === "text2") {
		return <StyledInput2 type='text' className={`${className}`} {...rest} />;
	}

	return (
		<StyledInput
			type={type}
			className={`${className}`}
			{...rest}
		/>
	);
};

const inputStyles = css`
	font-size: 1rem;
	line-height: 1.625rem;
	border-radius: 10px;
	height: 62px;
	border: 1px solid #c4c4c4;
	letter-spacing: -1px;
	color: var(--c-grey);
	background-color: transparent;

	&:focus {
		color: var(--c-grey);
		background-color: transparent;
	}

	&::placeholder {
		color: var(--c-grey);
	}
`;

const StyledInput = styled(Form.Control)`
	${inputStyles}
	resize: none;
	appearance: none;
	background: ${({ as }) =>
		as === "select"
			? `var(--c-d-black) url(${arrowDown}) no-repeat 96% center`
			: "var(--c-d-black)"};
`;

const StyledInput2 = styled(Form.Control)`
	${inputStyles}
	height: 42px;
	border-radius: 3px;
	appearance: none;
	background: ${({ as }) =>
		as === "select"
			? `transparent url(${arrowDown}) no-repeat 96% center`
			: ""};
`;

export const inputWithInLineButtonCss = css`
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0;
	border-right: none;
`;

export const inlineInputButton = css`
	border: 1px solid #c4c4c4;
	margin-left: -2px;
`;


Input.defaultProps = {
	className: "form-control",
	variant: "",
};

Input.propTypes = {
	type: PropTypes.string.isRequired,
	className: PropTypes.string,
	variant: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
};

export default Input;
