import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import Datetime from "react-datetime";
import Moment from "react-moment";
import { useFormik } from "formik";
import moment from "moment";
import "react-datetime/css/react-datetime.css";

// Internal dependencies
import Layout from "components/Layout";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { testPlansData } from "utils/data";
import { copyToClipboard, handleTableChange, useQuery } from "utils";
import PageTitle from "components/PageTitle";
import Button from "components/Button";
import ModalContainer from "components/ModalContainer";
import planService from "services/plan.service";
import { Col, Form, Spinner } from "react-bootstrap";
import ModalFormLabel from "components/ModalFormLabel";
import Input from "components/Input";
import config from "env";

const valid = (current) => current.isAfter(new Date() - 24 * 60 * 1000);

const Plans = () => {
	const [state, setState] = useState({
		page: 1,
		data: [],
	});

	const fromDashboard = useQuery().get("f");

	const [showDelModal, setShowDelModal] = useState(false);
	const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);

	const handleDelModalHide = () => setShowDelModal(false);
	const handleDelModalShow = () => setShowDelModal(true);

	const handleCreatePlanModalHide = () => setShowCreatePlanModal(false);
	const handleCreatePlanModalShow = () => setShowCreatePlanModal(true);
	const [error, setError] = useState(null);


	const columns = useMemo(
		() => [
			{
				dataField: "planName",
				text: "Name",
				formatter: (cell, { name }) => (
					<StyledLink to={`plans/${name}`}>{name}</StyledLink>
				),
			},
			{
				dataField: "status",
				text: "Status",
			},
			{
				dataField: "period",
				text: "Interval",
			},
			{
				dataField: "createdAt",
				text: "Created on",
				formatter: (cell, { createdAt }) => <Moment date={createdAt} />,
			},
			{
				dataField: "amount",
				text: "Amount",
			},
			{
				dataField: "key",
				text: "",
				align: "right",
				headerClasses: "bs-table-col-action",
				formatter: (cell, { key }) => (
					<div className="d-flex">
						<Button.TableCopyBtn text="Copy Link" onClick={() => {
							copyToClipboard(`${config.payUrl}/${key}`);
						}} />
						<Button.TableDelBtn
							text="Delete Plan"
							onClick={handleDelModalShow}
						/>
					</div>
				),
			},
		],
		[]
	);

	const getData = async () => {
		const all = await planService.fetch();
		// console.log(all);
		setState({
			page: all.data.page,
			data: all.data.plans,
		});
	};

	useEffect(() => {
		getData();
	}, []);

	const postFormData = async (v, setSubmitting) => {
		setError(null);
		const data = v;
		if(!v.payLater) data.startDate = moment().format('YYYY-MM-DD');
		// delete data.payLater;
		try {
			await planService.create(data);
			setSubmitting(false);
			getData();
			handleCreatePlanModalHide()
		} catch (err) {
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		errors, touched,
		isSubmitting,
		setSubmitting,
		isValid,
		setFieldValue
	} = useFormik({
		initialValues: {
			name: "",
			amount: "",
			interval: "weekly",
			payLater:null,
			startDate: moment().add(2, "days"),
			split: '',
		},
		// validationSchema: paySchema,
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
	});
	// Show Modal if redirected from dashboard
	useEffect(() => {
		if (fromDashboard === "12123672837") {
			setShowCreatePlanModal(true);
		}
	}, []);

	return (
		<>
			{/* Cancel Plan Modal */}
			<ModalContainer
				modalTitle="Cancel plan"
				show={showDelModal}
				onHide={handleDelModalHide}
			>
				<p>
					Cancelling this plan will stop all active subscriptions and prevent
					anyone from being added or joining.
				</p>
				<p>Please confirm that you want to cancel this plan.</p>
				<p className="mb-5">
					Any payments that have already been created, but are still pending,
					will need to be cancelled manually from the payments page.
				</p>

				<div className="d-flex">
					<Button
						variant="outline"
						className="px-4 mr-4"
						text="Back"
						onClick={handleDelModalHide}
					/>
					<Button variant="red" className="px-4" text="Yes, cancel plan" />
				</div>
			</ModalContainer>

			{/* Create Plan Modal */}
			<ModalContainer
				modalTitle="Create a new plan"
				show={showCreatePlanModal}
				onHide={handleCreatePlanModalHide}
			>
				<Form onSubmit={handleSubmit}>
						{error && (
							<div className="text-danger text-center">
								<p>*{error}</p>
							</div>
						)}
					<Form.Group>
						<ModalFormLabel>Name</ModalFormLabel>
						<Input
							type="text2"
							name="name"
							onChange={handleChange}
							value={values.name}
							onBlur={handleBlur}
						/>
						{errors.name && touched.name && (
							<span className="error">{errors.name}</span>
						)}
					</Form.Group>

					<Form.Row>
						<Col sm={6}>
							<Form.Group>
								<ModalFormLabel>Amount</ModalFormLabel>
								<Input
									type="text2"
									name="amount"
									onChange={handleChange}
									value={values.amount}
								/>
							</Form.Group>
						</Col>
						<Col sm={6}>
							<Form.Group>
								<ModalFormLabel>Currency</ModalFormLabel>
								<Input
									type="select2"
									value='NGN'
									onChange={handleChange}
									name="currency"
									readOnly
								>
									<option value="ngn">NGN</option>
								</Input>
							</Form.Group>
						</Col>
					</Form.Row>

					<Form.Group>
						<ModalFormLabel>Interval</ModalFormLabel>
						<Input
							type="select2"
							name="interval"
							value={values.interval}
							onChange={handleChange}
						>
							<option disabled>Select a period</option>
							<option value="weekly">Weekly</option>
							<option value="monthly">Monthly</option>
							<option value="yearly">Yearly</option>
						</Input>
						{errors.interval && touched.interval && (
							<span className="error">{errors.interval}</span>
						)}
					</Form.Group>

					<Form.Group>
						<Radio
							type="radio"
							name="payLater"
							label="Take payment as soon as possible"
							onChange={()=>setFieldValue('payLater', false)}
							value={values.payLater}
							onBlur={handleBlur}
						/>
						<Radio
							type="radio"
							name="payLater"
							label="Take payment on a specific date"
							onChange={()=>setFieldValue('payLater', true)}
							value={values.payLater}
							onBlur={handleBlur}
						/>
						{values.payLater && 
							<Datetime
								value={values.startDate}
								isValidDate={valid}
								timeFormat={false}
								onChange={(value) =>
									setFieldValue("startDate", value)
								}
							/>
						}
						{errors.startDate && touched.startDate && (
							<span className="error">{errors.startDate}</span>
						)}
					</Form.Group>

					<Form.Group>
						<ModalFormLabel>
							Number of payments to take (e.g 1, 2, 3)?*
						</ModalFormLabel>
						<Input type="text2" name="split"
							onChange={handleChange}
							value={values.split}
							onBlur={handleBlur} />
						{errors.split && touched.split && (
							<span className="error">{errors.split}</span>
						)}
					</Form.Group>

					{/* <Form.Group>
						<ModalFormLabel>Redirect Url (optional)</ModalFormLabel>
						<Input type="text2" name="firstName" />
					</Form.Group>

					<Form.Group className="mb-5">
						<div className="d-flex">
							<Input
								type="text2"
								name="redirectUrl"
								css={inputWithInLineButtonCss}
								value="https;//www.pay.collect.africa/dvwnvnwnw23e5"
							/>
							<button type="button" className="btn" css={inlineInputButton}>
								Copy
							</button>
						</div>
					</Form.Group> */}

					{/* <Button variant="green" className="px-4" text="Create Plan" /> */}
						<Button
							variant="green"
							className="px-4"
							text={
								isSubmitting ? <Spinner animation="grow" /> : "Create Plan"
							}
							onClick={handleSubmit}
							disabled={isSubmitting || !isValid}
						/>
				</Form>
			</ModalContainer>
			{/* Create Plan Modal End */}

			<Layout>
				<div className="d-flex align-items-center justify-content-between mb-4">
					<PageTitle text="Plans" />

					<Button
						variant="green"
						className="px-4"
						text="New Plan"
						onClick={handleCreatePlanModalShow}
					/>
				</div>

				<div>
					<Pagination size={testPlansData.length}>
						<Table
							keyField="customer"
							data={state.data}
							page={state.page}
							columns={columns}
							onTableChange={(type, { page, sizePerPage }) =>
								handleTableChange(
									type,
									{ page, sizePerPage },
									testPlansData,
									setState
								)
							}
							noDataIndication={() => (
								<p className="text-center">
									You haven&apos;t added any plan yet.
								</p>
							)}
						/>
					</Pagination>
				</div>
			</Layout>
		</>
	);
};

const StyledLink = styled(Link)`
	color: #3ab75d;

	:hover {
		color: #3ab75d;
	}
`;

const Radio = styled(Form.Check)`
	border: 1px solid #c4c4c4;
	border-radius: 3px;
	padding: 12px 0 13px;

	.form-check-input {
		position: relative;
		margin-left: 5px;
	}

	.form-check-label {
		margin-left: 8px;
	}
`;

export default Plans;
