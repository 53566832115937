import axiosClient from "axiosClient";
import endpoints from "utils/endpoints";

const service = {
    fetch:async(page=1, limit=10) =>{
        const respo =  await axiosClient.get(`${endpoints.paymentFetchAll}?page=${page}&&limit=${limit}`);
        return respo;
    },

    fetchOne:async(id) =>{
        const respo =  await axiosClient.get(endpoints.paymentFetchOne(id));
        return respo;
    },
}
export default service;