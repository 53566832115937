import React, { useState } from "react";
import styled from "styled-components/macro";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

// Internal dependencies
import Input from "components/Input";
import Button from "components/Button";
import "./auth.scss";
import GoogleIcon from "components/VectorIcons/Google";
import { signInAction } from "redux/actions/authActions";
import authService from "services/auth.service";
import { loginSchema } from "schema/schema";

const Login = () => {
	const { isAuthenticated } = useSelector((state) => state.auth);

	const history = useHistory();
	const dispatch = useDispatch();

	const [error, setError] = useState(null);
	// console.log(state);

	const postFormData = async (v, setSubmitting) => {
		setError(null);
		try{
			const response = await authService.login(v.email, v.password);
			dispatch(signInAction(response.data));
			setSubmitting(false);
			history.push("/dashboard");
		}catch(err){
			setError(err.response.data.message);
			setSubmitting(false);
		}
	};

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		isSubmitting,
		setSubmitting,
		errors, touched
	} = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema:loginSchema,
		onSubmit: (valueData) => postFormData(valueData, setSubmitting),
		// alert(JSON.stringify(valueData, null, 2));
	});

	if (isAuthenticated) {
		return <Redirect to="/dashboard" />;
	}

	return (
		<div className="row">
			<div className="d-none d-lg-block col-5 px-0">
				<div className="auth-fcol text-white">
					<h1 className='auth-fcol_title'>Collect Direct</h1>

					<p className='auth-fcol_content'>
						Your customer signs up – we take care of the rest. Collect allows
						you to easily set up and manage a Direct Debit payment schedule for
						your customers.
					</p>
				</div>
			</div>
			<div className="col-12 col-lg-7 px-0">
				<div className="auth-wrapper">
					<div className="auth-content">
						<form className="card auth-card" onSubmit={handleSubmit}>
							<h3 className="auth-card-title">Welcome back!</h3>
							{error && (
								<div className="text-danger text-center">
									<p>*{error}</p>
								</div>
							)}

							<div>
								<Form.Group>
									<Input
										type="text"
										placeholder="Email Address"
										name="email"
										onChange={handleChange}
										value={values.email}
										onBlur={handleBlur}
									/>
									{errors.email && touched.email && (
										<span className="error">{errors.email}</span>
									)}
								</Form.Group>

								<Form.Group>
									<Input
										type="password"
										placeholder="Password"
										name="password"
										onChange={handleChange}
										value={values.password}
										onBlur={handleBlur}
									/>
									{errors.password && touched.password && (
										<span className="error">{errors.password}</span>
									)}
								</Form.Group>

								<Form.Group className="d-flex justify-content-between mb-4">
									<CheckBox type="checkbox" label="Remember me" />

									<StyledLink to="/#">Forgot password?</StyledLink>
								</Form.Group>

								<div className="d-flex flex-column">
									<Button
										iconBefore={() =>
											!isSubmitting ? (
												""
											) : (
												<Spinner
													animation="grow"
													css="width:20px;height:20px;"
													variant="white"
												/>
											)
										}
										onClick={handleSubmit}
										className="w-100"
										variant="green"
										text={!isSubmitting ? "SIGN IN" : "Loading..."}
										disabled={isSubmitting}
									/>
									<div
										css="font-size:16px;color: var(--c-grey);"
										className="my-3 text-center"
									>
										OR
									</div>

									<Button
										iconBefore={GoogleIcon}
										className="w-100"
										variant="white"
										text="Sign in with Google"
										disabled
									/>
								</div>
							</div>
						</form>

						<div className="text-center auth-card-footLink">
							Don’t have an account? <Link to="/auth/sign-up">Sign Up</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const StyledLink = styled(Link)`
	color: var(--c-green);
	letter-spacing: -0.5px;
	font-size: 1rem;
	text-decoration: none;

	&:hover {
		color: var(--c-darken-green);
		text-decoration: none;
	}
`;

const CheckBox = styled(Form.Check)`
	font-size: 1rem;
	letter-spacing: -0.5px;
`;

export default Login;
