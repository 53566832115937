/* eslint-disable no-unused-vars */
import axios from "axios";
import { clearStorage } from "utils/auth";
import config from "./env";

// console.log(config);
const axiosClient = axios.create({
	baseURL: config.baseUrl,
	headers: {},
	// timeout: 5000,
});
const key = localStorage.getItem(config.authToken);
// console.log(key);
if (key) {
	axiosClient.defaults.headers.common.Authorization = `Bearer ${key}`;
	axiosClient.defaults.timeout = 5000;
}
axiosClient.defaults.headers.common["Content-Type"] = "application/json";
// axiosClient.defaults.headers.common["web-token"] = key;÷
// // Request Interceptor
// axiosClient.interceptors.request.use(
// 	(config) => {
// 		const reConfig = config;
// 		reConfig.headers["Content-Type"] = "application/json";
// 		reConfig.headers["web-token"] = key;
// 		return reConfig;
// 	},
// 	(error) => Promise.reject(error)
// );

// Response Interceptor
axiosClient.interceptors.response.use(
	(response) => response.data,
	(error) => {
		// console.log(error.response.status);
		if(error.response.status===403){
			clearStorage();
			window.location.reload();
			// return Promise.reject(error);
		}
		if (error.message === "Network Error") {
			const errorMessage = {
				response: {
					data: {
						message:
							"Network Error. Please check if you are connected to the internet.",
					},
				},
			};
			return Promise.reject(errorMessage);
		}

		return Promise.reject(error);
	}
);

export default axiosClient;
