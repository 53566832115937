import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

const Breadcrumb = ({ crumbList }) => (
	<div className="d-flex">
		{crumbList.map(({ path, name }, index) => {
			if (index + 1 === crumbList.length) {
				return <span key={(name, path)}>{name}</span>;
			}

			return (
				<span key={(name, path)}>
					<SLink to={path}>{name}</SLink>
					<span className="mx-3">
						<svg
							width={9}
							height={14}
							viewBox="0 0 9 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.53636 0.195312L0 1.71198L5.04805 6.69531L0 11.6786L1.53636 13.1953L8.12078 6.69531L1.53636 0.195312Z"
								fill="#686B6F"
							/>
						</svg>
					</span>
				</span>
			);
		})}
	</div>
);

const SLink = styled(Link)`
	color: var(--c-green);
	font-size: 1rem;
	line-height: 19px;

	:hover {
		color: var(--c-darken-green);
	}
`;

Breadcrumb.propTypes = {
	crumbList: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
		})
	).isRequired,
};

export default Breadcrumb;
