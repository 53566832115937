const env = process.env.REACT_APP_NODE_ENV || "local";
// console.log(env, process.env.REACT_APP_NODE_ENV);

let environment = {};

if (env === "prod") {
	environment = {
		env: "prod",
		baseUrl: "https://secureapi.collect.africa/v1",
		authToken: "userToken",
		payUrl: "http://pay.collect.africa",
		siteUrl: "http;//pay.collect.africa",
	};
} else if (env === "dev") {
	environment = {
		env: "dev",
		baseUrl: "https://stagingapi.collect.africa/v1",
		authToken: "userToken",
		payUrl: "http://direct-debit-app.s3-website.us-east-2.amazonaws.com",
		siteUrl: "http://direct-debit-app.s3-website.us-east-2.amazonaws.com",
	};
} else {
	environment = {
		env: "local",
		baseUrl: "http://localhost:9090/v1",
		authToken: "userToken",
		payUrl: "http://localhost:3001",
		siteUrl: "http://localhost:3000",
	};
}
const config = environment;

export default config;
