import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";

// Internal dependencies
import Layout from "components/Layout";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import { testCustomersData } from "utils/data";
import { handleTableChange, useQuery } from "utils";
import PageTitle from "components/PageTitle";
import Button from "components/Button";
import customerService from "services/customer.service";
import Input, {
	inputWithInLineButtonCss,
	inlineInputButton,
} from "components/Input";
import ModalContainer from "components/ModalContainer";

const formPText = css`
	font-size: 1rem;
	line-height: 19px;
	letter-spacing: 0.3px;
`;

const columns = [
	{
		dataField: "name",
		text: "Customer",
		formatter: (cell, { firstName, lastName, _id }) => (
			<StyledLink to={`/customers/${_id}`}>{firstName} - {lastName}</StyledLink>
		),
	},
	{
		dataField: "email",
		text: "Email",
	},
	{
		dataField: "mandate",
		text: "Mandate Status",
	},
	{
		dataField: "amount",
		text: "Total Amount Collected",
	},
];

const Customers = () => {
	const fromDashboard = useQuery().get("f");

	// const [ loading, setLoading ] = useState(true);
	const [showModal, setShowModal] = useState(false);

	const handleModalShow = () => setShowModal(true);
	const handleModalHide = () => setShowModal(false);

	const [state, setState] = useState({
		page: 1,
		limit: 10,
		data: [],
	});
	const getData = async () => {
		const respon = await customerService.fetch(state.page, state.limit);
		// console.log(respon);
		setState({
			page: respon.data.page,
			limit: respon.data.limit,
			data: respon.data.customers,
		});
	};

	useEffect(() => {
		getData();
	}, []);

	// Show Modal if redirected from dashboard
	useEffect(() => {
		if (fromDashboard === "12090444858") {
			setShowModal(true);
		}
	}, []);

	return (
		<>
			<ModalContainer
				modalTitle="Add customer"
				show={showModal}
				onHide={handleModalHide}
			>
				<div className="text-center">
					<p css={formPText} className="mb-5">
						Share this link online or in invoices to allow anyone to authorise
						payments to you.
					</p>
				</div>

				<div className="d-flex">
					<Input
						type="text2"
						name="firstName"
						value="https;//www.pay.collect.africa/dvwnvnwnw23e5"
						// onChange={handleChange}
						// onBlur={handleBlur}
						css={inputWithInLineButtonCss}
					/>
					<button type="button" className="btn" css={inlineInputButton}>
						Copy
					</button>
				</div>
			</ModalContainer>

			<Layout>
				<div className="d-flex align-items-center justify-content-between mb-4">
					<PageTitle text="Customers" />

					<Button
						variant="green"
						className="px-4"
						text="New Customer"
						onClick={handleModalShow}
					/>
				</div>

				<div>
					<Pagination size={testCustomersData.length}>
						<Table
							keyField="customer"
							data={state.data}
							page={state.page}
							columns={columns}
							onTableChange={(type, { page, sizePerPage }) =>
								handleTableChange(
									type,
									{ page, sizePerPage },
									testCustomersData,
									setState
								)
							}
							noDataIndication={() => (
								<p className="text-center">
									You haven&apos;t added any member yet.
								</p>
							)}
						/>
					</Pagination>
				</div>
			</Layout>
		</>
	);
};

const StyledLink = styled(Link)`
	color: #3ab75d;

	:hover {
		color: #3ab75d;
	}
`;

export default Customers;
