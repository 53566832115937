import { useLocation } from "react-router";

export const scrollToTop = (selector) => {
	if (selector) {
		document.querySelector(selector).scrollTo({ top: 0, behavior: "smooth" });
	} else {
		window.scrollTo({ top: 500, behavior: "smooth" });
	}
};

export const handleTableChange = (
	type,
	{ page, sizePerPage },
	data,
	callback
) => {
	const currentIndex = (page - 1) * sizePerPage;
	if (currentIndex >= data.length || currentIndex < 0) return;

	callback({
		page,
		data: data.slice(currentIndex, currentIndex + sizePerPage),
	});
};



export const currencyFormm = (val) => Number(val).toLocaleString();
export const currencyForm = (number) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencySign: "accounting",
  }).format(number);


export const copyToClipboard = (text) => {
	const selBox = document.createElement("textarea");
	selBox.style.position = "fixed";
	selBox.style.left = "0";
	selBox.style.top = "0";
	selBox.style.opacity = "0";
	selBox.value = text;
	document.body.appendChild(selBox);
	selBox.focus();
	selBox.select();
	document.execCommand("copy");
	document.body.removeChild(selBox);
	// callback("copied");
};

export const useQuery = () => new URLSearchParams(useLocation().search);
