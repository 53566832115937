import axiosClient from "axiosClient";
import endpoints from "utils/endpoints";
// import config from "env";


const setHeaders = (token) => {
    axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`;
}


const service = {
    login:async(email, password) =>{
        const respo =  await axiosClient.post(endpoints.login, { email, password });
        if(respo.status==='success') setHeaders(respo.data.token);
        return respo;
    },

    register:async(v) =>{
        const respo =  await axiosClient.post(endpoints.register, v);
        // console.log('registerrr===>', respo);
        if(respo.status==='success') setHeaders(respo.data.token);
        return respo;
    },

    getProfile:async()=>{
        const repo =await axiosClient.get(endpoints.setting);
        return repo;
    }
};

export default service;