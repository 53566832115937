const generator = (quantity, callback) =>
	Array.from({ length: quantity }, callback);

const testData = generator(50, (value, index) => ({
	num: index + 1,
	title: "Carribean Dancer",
	"song-count": 10,
	year: "2019",
	"date-uploaded": "10/2/20, 3.32 PM",
	status: "Uploaded",
}));

const testPaymentData = generator(3, (value, index) => ({
	num: index + 1,
	customer: "Linkup Missisippi Logistics",
	status: "Pending",
	description: "Rent",
	charged: "₦14,000,000.00",
	"paid-out": "₦5,000,000.00",
	amount: "₦3,000.00",
}));

const testPayoutData = generator(1, (value, index) => ({
	num: index + 1,
	reference: "Akon-wmnf-werm",
	status: "Successful",
	"paid-on": "23.11.2029",
	amount: "₦14,000,000.00",
}));

const testCustomersData = generator(1, (value, index) => ({
	num: index + 1,
	customer: "Linkup Missisippi Logistics",
	email: "linkuplogistics@gmail.com",
	status: "Confirmed",
	amount: "₦14,000,000.00",
}));

const testCustomerPlanData = generator(1, (value, index) => ({
	num: index + 1,
	plan: "Gold Plan",
	interval: "1",
	noOfPayments: "1",
	amount: "₦14,000",
	status: "Active",
}));

const testPlansData = generator(1, (value, index) => ({
	num: index + 1,
	planName: "Gold Plan",
	status: "Active",
	interval: "Monthly",
	createdOn: "23.11.2029",
	amount: "₦14,000,000.00",
}));

const testPlanCustomerData = generator(1, (value, index) => ({
	num: index + 1,
	customer: "Linkup Missisippi Logistics",
	status: "Active",
	amount: "₦14,000,000.00",
}));

const testPayLinksData = generator(1, (value, index) => ({
	num: index + 1,
	pageName: "Church Donations",
	payments: 0,
	createdOn: "23.11.2029",
	amount: "₦14,000,000.00",
}));

const testBanksData = generator(1, (value, index) => ({
	num: index + 1,
	bankName: "Gurantee Trust Bank",
	accountName: "Funmilayo Enterprises",
	accountNo: "0123456789",
	currency: "NGN",
}));


export {
	testData,
	testPaymentData,
	testPayoutData,
	testCustomersData,
	testPlansData,
	testPayLinksData,
	testCustomerPlanData,
	testPlanCustomerData,
	testBanksData,
};
