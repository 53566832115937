import axiosClient from "axiosClient";
import endpoints from "utils/endpoints";

const service = {
    fetchBankList:async() =>{
        const respo =  await axiosClient.get(`${endpoints.setting}/bankList`);
        return respo;
    },

    fetchAccounts:async(page=1, limit=10) =>{
        const respo =  await axiosClient.get(`${endpoints.bankUrl}?page=${page}&&limit=${limit}`);
        return respo;
    },
    
    addAccount:async(data) => {
        const respo =  await axiosClient.post(`${endpoints.bankUrl}`, data);
        return respo;
    },

    updateAccount:async(code, data) => {
        const respo =  await axiosClient.post(`${endpoints.bankUrl}/${code}`, data);
        return respo;
    },

    deleteAccount:async(code) => {
        const respo =  await axiosClient.delete(`${endpoints.bankUrl}/${code}`);
        return respo;
    },

    updateProfile:async(data) => {
        const respo =  await axiosClient.patch(`${endpoints.setting}`, data);
        return respo;
    },
    validateAccount:async(bankCode, accountNumber) => {
        const respo = await axiosClient.post(`${endpoints.setting}/bankList/validate`, { bankCode, accountNumber });
        return respo;
    },
    uploadFile:async(file)=>{
        const formData = new FormData();
        formData.append('file', file, file.name);
        const respo = await axiosClient.post(`${endpoints.upload}`, formData);
        return respo;
    }   

}
export default service;