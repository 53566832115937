import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

// Internal dependencies
import Layout from "components/Layout";
import PageTitle from "components/PageTitle";
import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import Pagination from "components/Table/Pagination";
import Table from "components/Table";
import { testPlanCustomerData } from "utils/data";
import { handleTableChange } from "utils";
import ModalContainer from "components/ModalContainer";
import ModalFormLabel from "components/ModalFormLabel";
import Input, { inlineInputButton, inputWithInLineButtonCss, } from "components/Input";

const formPText = css`
	font-size: 1rem;
	line-height: 19px;
	letter-spacing: 0.3px;
`;

const randomColor = () => {
	const colors = ["#FECA2D", "#3AB75D", "#F90217", "#1976D2"];
	const index = Math.ceil(Math.random() * colors.length);

	return colors[index];
};

const columns = [
	{
		dataField: "customer",
		text: "Customer",
		formatter: (cell, { customer }) => (
			<StyledLink to={`/customers/${customer}`}>{customer}</StyledLink>
		),
	},
	{
		dataField: "status",
		text: "Status",
	},
	{
		dataField: "amount",
		text: "Amount",
	},
];

const Plans = ({ crumbList }) => {
	const [state, setState] = useState({
		page: 1,
		data: testPlanCustomerData.slice(0, 10),
	});

	const [showEditModal, setShowEditModal] = useState(false);
	const [showDelModal, setShowDelModal] = useState(false);
	const [showInviteModal, setShowInviteModal] = useState(false);

	const handleDelModalHide = () => setShowDelModal(false);
	const handleDelModalShow = () => setShowDelModal(true);

	const handleEditModalHide = () => setShowEditModal(false);
	const handleEditModalShow = () => setShowEditModal(true);

		const handleInviteModalHide = () => setShowInviteModal(false);
		const handleInviteModalShow = () => setShowInviteModal(true);


	return (
		<>
			{/* Edit Plan Modal */}
			<ModalContainer
				modalTitle="Edit"
				show={showEditModal}
				onHide={handleEditModalHide}
			>
				<Form>
					<Form.Group>
						<ModalFormLabel>Name</ModalFormLabel>
						<Input
							type="text2"
							placeholder=""
							name="bankName"
							// value={values.bankName}
							// onBlur={handleBlur}
							// onChange={handleChange}
						/>
					</Form.Group>

					<Form.Group className="mb-5">
						<ModalFormLabel>Redirect Url (optional)</ModalFormLabel>
						<Input
							type="text2"
							placeholder=""
							name="bankName"
							// value={values.bankName}
							// onBlur={handleBlur}
							// onChange={handleChange}
						/>
					</Form.Group>
					<div className="d-flex justify-content-end">
						<Button
							variant="green"
							className="px-4"
							text="Done"
							onClick={handleEditModalHide}
						/>
					</div>
				</Form>
			</ModalContainer>

			{/* Delete Plan Modal */}
			<ModalContainer
				modalTitle="Cancel plan"
				show={showDelModal}
				onHide={handleDelModalHide}
			>
				<p className="mb-3">
					Cancelling this plan will stop all active subscriptions and prevent
					anyone from being added or joining.
				</p>
				<p className="mb-3">
					Please confirm that you want to cancel this plan.
				</p>
				<p className="mb-5">
					Any payments that have already been created, but are still pending,
					will need to be cancelled manually from the payments page.
				</p>

				<div className="d-flex">
					<Button
						variant="outline"
						className="px-4 mr-4"
						text="Back"
						onClick={handleDelModalHide}
					/>
					<Button variant="red" className="px-4" text="Yes, cancel plan" />
				</div>
			</ModalContainer>

			{/* Invite Customers Modal */}
			<ModalContainer
				modalTitle="Invite customers"
				show={showInviteModal}
				onHide={handleInviteModalHide}
			>
				<div className="text-center">
					<p css={formPText} className="mb-5">
						Use this link on your website or in emails to allow anyone to sign
						up to your plan
					</p>
				</div>

				<div className="d-flex">
					<Input
						type="text2"
						name="firstName"
						value="https;//www.pay.collect.africa/dvwnvnwnw23e5"
						// onChange={handleChange}
						// onBlur={handleBlur}
						css={inputWithInLineButtonCss}
					/>
					<button type="button" className="btn" css={inlineInputButton}>
						Copy
					</button>
				</div>
			</ModalContainer>

			<Layout>
				<div className="d-flex justify-content-between">
					<div className="d-flex flex-column mb-5">
						<PageTitle text="Plans" />
						<Breadcrumb crumbList={crumbList} />
					</div>

					<div className="d-flex">
						<Button
							variant="green"
							text="Edit"
							className="mr-2 px-5"
							onClick={handleEditModalShow}
						/>
						<Button
							variant="red"
							text="Cancel"
							className="mr-2 px-5"
							onClick={handleDelModalShow}
						/>
						<Button
							variant="green"
							text="Invite Customers"
							className="mr-2 px-5"
							onClick={handleInviteModalShow}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-lg-4">
						<CardHeader>
							<div className="d-flex justify-content-between">
								<PageTitle.H4 className="mb-0" text="Details" />

								<p className="mb-0 mr-3">Edit</p>
							</div>
						</CardHeader>

						<CardRow>
							<p>Created:</p>
							<p>4th March 2032</p>
						</CardRow>
						<CardRow>
							<p>Status:</p>
							<p>Active</p>
						</CardRow>
						<CardRow>
							<p>Number of payments:</p>
							<p>Direct Debit</p>
						</CardRow>
						<CardRow>
							<p>Collect:</p>
							<p>₦50,000 every month</p>
						</CardRow>
					</div>
					<div className="col-12 col-lg-8">
						<div>
							<Pagination size={testPlanCustomerData.length}>
								<Table
									keyField="customer"
									data={state.data}
									page={state.page}
									columns={columns}
									onTableChange={(type, { page, sizePerPage }) =>
										handleTableChange(
											type,
											{ page, sizePerPage },
											testPlanCustomerData,
											setState
										)
									}
									noDataIndication={() => (
										<p className="text-center">
											You haven&apos;t added any member yet.
										</p>
									)}
								/>
							</Pagination>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
};

const CardHeader = styled.div`
	background: #f4f4f4;
	border-radius: 6px 6px 0px 0px;
	padding: 16px 15px 13px;
`;

const CardRow = styled.div`
	background: var(--white);
	border-radius: 6px;
	display: flex;
	padding: 15px 28px;
	margin-top: 2px;

	p:first-child,
	p:last-child {
		width: 50%;
		font-size: 1rem;
		line-height: 19px;
		letter-spacing: -0.5px;
		margin: 0;
	}

	ul.list-unstyled li {
		padding: 12px 0;

		span.ball {
			position: relative;

			::before {
				position: absolute;
				content: "";
				width: 18px;
				height: 18px;
				top: 1px;
				background-color: ${randomColor()};
				border-radius: 50%;
				z-index: 1;
			}

			::after {
				position: absolute;
				content: "";
				width: 1px;
				height: 50px;
				bottom: -39px;
				left: 8.485px;
				background-color: var(--c-black);
			}
		}

		&:last-child {
			span.ball {
				::after {
					content: none;
				}
			}
		}
	}
`;

const StyledLink = styled(Link)`
	color: #3ab75d;

	:hover {
		color: #3ab75d;
	}
`;

Plans.propTypes = {
	crumbList: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
		})
	).isRequired,
};

export default Plans;
