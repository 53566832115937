/* eslint-disable react/react-in-jsx-scope */

import { Helmet } from "react-helmet";

import config from "env";

const Head = () =>(
        <Helmet>
        {config.env === 'prod' && 
        <>
            <title>Collect — for one-off and recurring payments</title>
            <meta name="title" content="Collect — for one-off and recurring payments" />
            <meta name="description" content="Use collect for one-off and recurring payments. Say Goodbye to late payments forever. Get 10 free transactions when you sign up." />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://metatags.io/" />
            <meta property="og:title" content="Collect — for one-off and recurring payments" />
            <meta property="og:description" content="Use collect for one-off and recurring payments. Say Goodbye to late payments forever. Get 10 free transactions when you sign up." />
            <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://metatags.io/" />
            <meta property="twitter:title" content="Collect — for one-off and recurring payments"/>
            <meta property="twitter:description" content="Use collect for one-off and recurring payments. Say Goodbye to late payments forever. Get 10 free transactions when you sign up." />
            <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png" />

            <script>
                {`function initFreshChat() {
                    window.fcWidget.init({
                    token: "39145d8a-0026-4b7e-b062-b02ad0a3ca22",
                    host: "https://wchat.freshchat.com"
                    });
                }
                function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"freshchat-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
                `}
            </script>
            </>
            }
        </Helmet>
                
    )

    export default Head;