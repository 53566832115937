/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, {
	Children,
	cloneElement,
	isValidElement,
	useState,
	useMemo,
} from "react";
import paginationFactory, {
	PaginationProvider,
} from "react-bootstrap-table2-paginator";
import styled, { css } from "styled-components/macro";
import { scrollToTop } from "utils";

const selector = "";

const Pagination = ({ size, children }) => {
	const [sizePerPage, setSizePerPage] = useState(10);

	const options = useMemo(
		() => ({
			custom: true,
			totalSize: size,
			sizePerPage,
		}),
		[sizePerPage]
	);

	const handleNextPage = ({ page, onPageChange }) => () => {
		onPageChange(page + 1);
		scrollToTop(selector);
	};

	const handlePrevPage = ({ page, onPageChange }) => () => {
		onPageChange(page - 1);
		scrollToTop(selector);
	};

	const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
		onSizePerPageChange(newSizePerPage, page);
	};

	const handleSelectChange = (e, paginationProps) => {
		const { value } = e.target;
		setSizePerPage(+value);
		handleSizePerPage(paginationProps, +value);
		scrollToTop(selector);
	};

	const childrenWithProps = (props) =>
		Children.map(children, (child) => {
			if (isValidElement(child)) {
				return cloneElement(child, { ...props, remote: true });
			}

			return child;
		});

	return (
		<PaginationProvider pagination={paginationFactory(options)}>
			{({ paginationProps, paginationTableProps }) => (
				<div>
					{childrenWithProps(paginationTableProps)[0]}
					<div className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							<Btn
								type="button"
								className="btn mr-2"
								onClick={handlePrevPage(paginationProps)}
							>
								<svg
									width={10}
									height={16}
									viewBox="0 0 10 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M9.13054 0.269475C9.42343 0.562368 9.42343 1.03724 9.13054 1.33013L2.46087 7.9998L9.13054 14.6695C9.42343 14.9624 9.42343 15.4372 9.13054 15.7301C8.83765 16.023 8.36277 16.023 8.06988 15.7301L0.869878 8.53013C0.729225 8.38948 0.650208 8.19872 0.650208 7.9998C0.650208 7.80089 0.729225 7.61013 0.869878 7.46947L8.06988 0.269475C8.36277 -0.0234186 8.83765 -0.0234186 9.13054 0.269475Z"
										fill="white"
									/>
								</svg>
							</Btn>

							<CurrentPage>{paginationProps.page}</CurrentPage>
							<span className="mx-2">/</span>
							<span
								css="color:var(--c-blue);font-size: 0.875rem;line-height: 1.5rem;"
								className="mr-2"
							>
								{Math.ceil(+size / options.sizePerPage)}
							</span>

							<Btn
								type="button"
								className="btn"
								onClick={handleNextPage(paginationProps)}
							>
								<svg
									width={10}
									height={16}
									viewBox="0 0 10 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.869878 0.269475C0.576984 0.562368 0.576984 1.03724 0.869878 1.33013L7.53955 7.9998L0.869878 14.6695C0.576984 14.9624 0.576984 15.4372 0.869878 15.7301C1.16277 16.023 1.63764 16.023 1.93054 15.7301L9.13054 8.53013C9.27119 8.38948 9.35021 8.19872 9.35021 7.9998C9.35021 7.80089 9.27119 7.61013 9.13054 7.46947L1.93054 0.269475C1.63764 -0.0234186 1.16277 -0.0234186 0.869878 0.269475Z"
										fill="white"
									/>
								</svg>
							</Btn>
						</div>

						<div>
							<span
								className="mr-3"
								css="color:var(--c-dark-grey);font-size: 0.875rem;line-height: 1.5rem;"
							>
								Rows per page
							</span>

							<Select
								value={paginationProps.sizePerPage}
								onChange={(e) => handleSelectChange(e, paginationProps)}
							>
								<option value="10">10</option>
								<option value="15">15</option>
								<option value="20">20</option>
								<option value="25">25</option>
								<option value="30">30</option>
							</Select>
						</div>
					</div>
				</div>
			)}
		</PaginationProvider>
	);
};

const boxStyles = css`
	width: 32px;
	height: 32px;
	background-color: var(--c-d-black);
	position: relative;
`;

const octagonStyles = css`
	${boxStyles}
	&:before {
		content: "";
		width: 32px;
		height: 0;
		position: absolute;
		top: -1px;
		left: -1px;
		border-bottom: 6px solid var(--c-d-black);
		border-left: 5px solid var(--bg-color);
		border-right: 5px solid var(--bg-color);
	}

	&:after {
		content: "";
		width: 32px;
		height: 0;
		position: absolute;
		bottom: -1px;
		left: -1px;
		border-top: 6px solid var(--c-d-black);
		border-left: 5px solid var(--bg-color);
		border-right: 5px solid var(--bg-color);
	}
`;

const Btn = styled.button`
	${octagonStyles}

	display:flex;
	justify-content: center;
	align-items: center;
`;

const CurrentPage = styled.div`
	${boxStyles}
	color: var(--c-red);
	font-size: 0.875rem;
	line-height: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(255, 255, 255, 0.2);
`;

const Select = styled.select`
	${boxStyles}
	font-size: 0.875rem;
	line-height: 1.5rem;
	width: 60px;
	color: var(--c-white);
	border-radius: none !important;
`;

export default Pagination;
