/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

// eslint-disable-next-line react/prop-types
const ModalFormLabel = ({ children, ...rest }) => (
	<StyledLabel {...rest}>{children}</StyledLabel>
);

const StyledLabel = styled(Form.Label)`
	font-size: 14px;
	line-height: 17px;
	color: var(--c-black);
`;

export default ModalFormLabel;
