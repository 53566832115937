import React from 'react';

const PlansIcon = () => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2 8C2 6.34315 3.34315 5 5 5H19C20.6569 5 22 6.34315 22 8V16C22 17.6569 20.6569 19 19 19H5C3.34315 19 2 17.6569 2 16V8ZM16 7H19C19.5523 7 20 7.44771 20 8V16C20 16.5523 19.5523 17 19 17H16V7ZM14 7H10V17H14V7ZM8 17V7H5C4.44772 7 4 7.44772 4 8V16C4 16.5523 4.44772 17 5 17H8Z"
			fill="white"
		/>
	</svg>
);

export default PlansIcon;
