/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import { useParams } from 'react-router';

// Internal dependencies
import Layout from "components/Layout";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
// import { testCustomerPlanData } from "utils/data";
import { handleTableChange } from "utils";
import PageTitle from "components/PageTitle";
import Breadcrumb from "components/Breadcrumb";
import customerService from "services/customer.service";

const columns = [
	{
		dataField: "plan",
		text: "Plan",
	},
	{
		dataField: "interval",
		text: "Interval",
	},
	{
		dataField: "noOfPayments",
		text: "Number of payments",
	},
	{
		dataField: "paymentsRemaining",
		text: "Payments Remaining",
	},
	{
		dataField: "amount",
		text: "Amount",
	},
	{
		dataField: "status",
		text: "Status",
	},
];

const Customer = ({ crumbList }) => {
	const [customer, setCustomer] = useState(null);
	const params = useParams();
	const [state, setState] = useState({
		page: 1,
		data: [],
	});
	const getData = async (id) => {
		const respo = await customerService.fetchOne(id);
		setCustomer(respo.data);
	}
	const getPlans = async (id) => {
		const activePlans = await customerService.fetchActivePlans(id, state.page);
		const respo = activePlans.data;
		const plans = respo.plans.map((p)=>({
				plan: p.plan.name,
				interval: p.plan.period,
				noOfPayments: p.payments.length,
				paymentsRemaining: p.paymentsRemaining,
				amount: p.total,
				status: p.status
			}))
		setState({
			page: respo.page,
			data: plans
		})
	}
	useEffect(()=>{
		getData(params.customerId);
		getPlans(params.customerId);
	}, [params])

	return (
		<Layout>
			<div className="d-flex flex-column mb-5">
				<PageTitle text="Customers" />
				<Breadcrumb crumbList={crumbList} />
			</div>

			{customer ? <div className="row">
				<div className="col-12 col-xl-10">
					<div className="card border-0 mb-4">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<ImageContainer className="d-flex flex-column align-items-center justify-content-center rounded-circle mr-3">
									<svg
										width={39}
										height={39}
										viewBox="0 0 39 39"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M9.89478 27.3684H29.0176C34.2982 27.3684 38.579 31.6492 38.579 36.9298C38.579 37.9859 37.7228 38.842 36.6667 38.842C35.686 38.842 34.8778 38.1038 34.7673 37.1528L34.7447 36.5927C34.5767 33.6927 32.2546 31.3707 29.3547 31.2027L29.0176 31.1929H9.89478C6.72641 31.1929 4.15794 33.7614 4.15794 36.9298C4.15794 37.9859 3.30178 38.842 2.24565 38.842C1.18953 38.842 0.333374 37.9859 0.333374 36.9298C0.333374 31.7881 4.39182 27.5944 9.48002 27.3772L9.89478 27.3684H29.0176H9.89478ZM19.4562 0.596436C25.7929 0.596436 30.9299 5.73338 30.9299 12.0701C30.9299 18.4069 25.7929 23.5438 19.4562 23.5438C13.1194 23.5438 7.9825 18.4069 7.9825 12.0701C7.9825 5.73338 13.1194 0.596436 19.4562 0.596436ZM19.4562 4.421C15.2317 4.421 11.8071 7.84563 11.8071 12.0701C11.8071 16.2946 15.2317 19.7192 19.4562 19.7192C23.6807 19.7192 27.1053 16.2946 27.1053 12.0701C27.1053 7.84563 23.6807 4.421 19.4562 4.421Z"
											fill="black"
										/>
									</svg>
								</ImageContainer>

								<div className="ml-3">
									<PageTitle.H4 className="m-0" text={customer.company !=="null" ? customer.company :`${customer.firstName}-${customer.lastName}`} />

									<p css="font-size:1rem;line-height: 19px;" className="m-0">
										Cust-{customer.customerId}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="card border-0 mb-4">
						<div className="card-body">
							<header className="d-flex justify-content-between px-4 mb-3 mt-1">
								<PageTitle.H4 className="m-0" text="Profile" />

								<p className="m-0">Edit</p>
							</header>

							<div className="px-4">
								<Row className="row">
									<div className="col-12 col-sm-3">
										<Label className="mb-1">Email:</Label>
										<Content className="mb-1">
											{customer.email}
										</Content>
									</div>
									<div className="col-12 col-sm-3">
										<Label className="mb-1">Phone Number:</Label>
										<Content className="mb-1">{customer.phone}</Content>
									</div>
									<div className="col-12 col-sm-3">
										<Label className="mb-1">First Name:</Label>
										<Content className="mb-1">{customer.firstName}</Content>
									</div>
									<div className="col-12 col-sm-3">
										<Label className="mb-1">Last Name:</Label>
										<Content className="mb-1">{customer.lastName}</Content>
									</div>
								</Row>

								<Row className="row">
									<div className="col-12 col-sm-3">
										<Label className="mb-1">Company name: </Label>
										<Content className="mb-1">{customer.company}</Content>
									</div>
									{/* <div className="col-12 col-sm-3">
										<Label className="mb-1">Bank Name:</Label>
										<Content className="mb-1">{customer.bank}</Content>
									</div>
									<div className="col-12 col-sm-3">
										<Label className="mb-1">Account Number:</Label>
										<Content className="mb-1">{customer.accountNo}</Content>
									</div> */}
								</Row>
							</div>
						</div>
					</div>
				</div>
			</div> : 
			<Spinner />}

			{customer && <div>
				<Pagination size={state.data.length}>
					<Table
						keyField="customer"
						data={state.data}
						page={state.page}
						columns={columns}
						onTableChange={(type, { page, sizePerPage }) =>
							handleTableChange(
								type,
								{ page, sizePerPage },
								[],
								setState
							)
						}
						noDataIndication={() => (
							<p className="text-center">
								No Active Plans.
							</p>
						)}
					/>
				</Pagination>
			</div>}
		</Layout>
	);
};

const Row = styled.div`
	padding: 15px 0;
	border-top: 0.5px solid #f1f0f0;
`;

const Label = styled.p`
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.0625rem;
`;

const Content = styled.p`
	font-size: 0.875rem;
	line-height: 1.0625rem;
`;

const ImageContainer = styled.div`
	width: 109px;
	height: 109px;
	background: #c4c4c4;
`;

Customer.propTypes = {
	crumbList: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
		})
	).isRequired,
};

export default Customer;
