import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Dropdown, Spinner } from "react-bootstrap";

// Internal dependencies
import Layout from "components/Layout";
import PageTitle from "components/PageTitle";
import Breadcrumb from "components/Breadcrumb";
import CustomToggle from "components/CustomToggle";
import Button from "components/Button";
import paymentService from "services/payment.service";
import { useParams } from "react-router";
import Moment from "react-moment";

// status = 'created', 'submitted', 'failed', 'retried', 'confirmed', 'cancelled'

const randomColor = () => {
	const colors = ["#FECA2D", "#3AB75D", "#F90217", "#1976D2"];
	const index = Math.ceil(Math.random() * colors.length)

	return colors[index]
}


const StyledCB = styled(CustomToggle)`
	background-color: var(--c-green);
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.5px;
	color: var(--white);
	height: 50px;

	&:hover {
		background-color: var(--c-darken-green);
		color: var(--white);
	}
`;

const Payment = ({ crumbList }) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const params = useParams();
	const getData = async (id) => {
		const all = await paymentService.fetchOne(id);
		setData(all.data);
		setLoading(false);
	};

	useEffect(() => {
		getData(params.paymentId);
	}, []);

	return(
	<Layout>
		<div className="d-flex justify-content-between">
			<div className="d-flex flex-column mb-5">
				<PageTitle text="Payments" />
				<Breadcrumb crumbList={crumbList} />
			</div>

			<div className="d-flex">
				<Button variant="outline" text="Refund" className="mr-2 px-5" />

				<Dropdown>
					<Dropdown.Toggle className="px-3" as={StyledCB}>
						Actions
					</Dropdown.Toggle>

					<Dropdown.Menu className="border-0">
						<Dropdown.Item css="font-size:14px" href="#/action-1">
							Download PDF
						</Dropdown.Item>
						<Dropdown.Item css="font-size:14px" href="#/action-2">
							Send Receipt
						</Dropdown.Item>
						<Dropdown.Item
							className="text-danger"
							css="font-size:14px"
							href="#/action-3"
						>
							Cancel Payment
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</div>

		{!loading ? <div className="row">
			<div className="col-12 col-lg-4">
				<CardHeader>
					<div className="d-flex justify-content-between">
						<PageTitle.H4 className="mb-0" text="Payment details" />
						{/* <p className="mb-0 mr-3">Edit</p> */}
					</div>
				</CardHeader>

				<CardRow>
					<p>Customer:</p>
					<p>{data.customer.name}</p>
				</CardRow>
				<CardRow>
					<p>Status:</p>
					<p>{data.status}</p>
				</CardRow>
				<CardRow>
					<p>Payment method:</p>
					<p>Direct Debit</p>
				</CardRow>
				<CardRow>
					<p>Paid:</p>
					<p>{data.amount}</p>
				</CardRow>
			</div>
			<div className="col-12 col-lg-8">
				<CardHeader>
					<div className="d-flex justify-content-between">
						<PageTitle.H4 className="mb-0" text="Payment History" />
					</div>
				</CardHeader>

				<CardRow className="p-lg-5">
					<ul className="list-unstyled p-0">
						{data.history && data.history.length > 0 && data.history.map((h) =><li>
							<span><Moment date={h.createdAt}  format="DD.MM.YY"/></span> <span className="ball mx-5" />
							<span>{h.status}</span>
						</li>)}
					</ul>
				</CardRow>
			</div>
		</div> : <Spinner animation="grow" />}
	</Layout>
	)
};

const CardHeader = styled.div`
	background: #f4f4f4;
	border-radius: 6px 6px 0px 0px;
	padding: 16px 15px 13px;
`;

const CardRow = styled.div`
	background: var(--white);
	border-radius: 6px;
	display: flex;
	padding: 15px 28px;
	margin-top: 2px;

	p:first-child,
	p:last-child {
		width: 50%;
		font-size: 1rem;
		line-height: 19px;
		letter-spacing: -0.5px;
		margin: 0;
	}

	ul.list-unstyled li {
		padding: 12px 0;

		span.ball {
			position: relative;

			::before {
				position: absolute;
				content: "";
				width: 18px;
				height: 18px;
				top: 1px;
				background-color: ${randomColor()};
				border-radius: 50%;
				z-index: 1;
			}

			::after {
				position: absolute;
				content: "";
				width: 1px;
				height: 50px;
				bottom: -39px;
				left: 8.485px;
				background-color: var(--c-black);
			}
		}

		&:last-child {
			span.ball {
				::after {
					content: none;
				}
			}
		}
	}
`;

Payment.propTypes = {
	crumbList: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
		})
	).isRequired,
};

export default Payment;
