import axiosClient from "axiosClient";
import endpoints from "utils/endpoints";

const service = {
    fetch:async(page=1, limit=10) =>{
        const respo =  await axiosClient.get(`${endpoints.customerFetchAll}?page=${page}&&limit=${limit}`);
        return respo;
    },

    fetchOne:async(id) =>{
        const respo =  await axiosClient.get(endpoints.customerFetchOne(id));
        return respo;
    },

    fetchActivePlans:async(id, page=1, limit=10) =>{
        const respo =  await axiosClient.get(`${endpoints.customerFetchOne(id)}/plans?page=${page}&&limit=${limit}`);
        return respo;
    },

}
export default service;